.loader {position: absolute; left: 0px; border-radius: 0%; width: 100%; height: 100%; display: flex; top: 0px; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.7);
    span { position: absolute; display: inline-block; width: 50px; height: 50px; border-radius: 100%; background:rgba(135, 211, 124,1); -webkit-animation: loader3 1.5s linear infinite; animation: loader3 1.5s linear infinite;
        &:last-child { animation-delay:-0.9s; -webkit-animation-delay:-0.9s;}
    }
 }
 .fb{font-size: 13px;
    background-color: #1d82ec;
    color: #fff;
    padding: 3px;
    margin-right: 10px;
    border-radius: 4px;}
 .fb:hover{
	 text-decoration:none;
	 color: #fff;
 }	
 @keyframes loader3 {
    0% {transform: scale(0, 0);opacity:0.8;}
    100% {transform: scale(1, 1);opacity:0;}
 }
 @-webkit-keyframes loader3 {
    0% {-webkit-transform: scale(0, 0);opacity:0.8;}
    100% {-webkit-transform: scale(1, 1);opacity:0;}
 } // Profile Loading css end

.css-scroll {scrollbar-color: $gray-300 $gray-200; scrollbar-width: thin;
	&::-webkit-scrollbar {width: 8px; background-color: $gray-200;} 
	&::-webkit-scrollbar-thumb {background-color: $gray-300; border-radius:10px;}
	&::-webkit-scrollbar-thumb:hover {background-color: $gray-300; border:1px solid $gray-300;}
	&::-webkit-scrollbar-thumb:active {background-color: $gray-300; border:1px solid $gray-300;} 
}
.mat-form-field {width: 100%;}

.booking-box {box-shadow: 0px 0px 6px rgba($black, 0.1); border-radius: 8px; margin-bottom: 30px; padding: 20px; position: relative; transition: all 0.3s ease-in-out; overflow: hidden; display: flex;
	.profile-group {display: flex; width: 40%;
		.photo {width: 113px; margin-right: 28px;
			img {width: 113px; max-width: 113px; height: 113px; border-radius: 100%; object-fit: cover; object-position: center;}
		}
	}
	.user-details {padding-top: 0px;
		h5 {font-size: 20px; margin-bottom: 7px; font-weight: 500; color: $secondary;
			a {color: $secondary;}
			a:hover {text-decoration: none; color: $primary;}
		} 
		.rating {margin-bottom: 10px;
			ngb-rating {font-size: 24px; margin-right: 10px; color: $primary; vertical-align: top;}
			small {color: lighten($black, 40%);}
		}
		.location {font-weight: 500; padding: 0 45px 0 0;
			img {margin: 2px 15px 20px 0; float: left;}
		}
	}
	.time-group {width: 35%; padding-top: 30px;
		> .row {margin-bottom: 15px;
			[class*="col"] {display: flex;}
		}
		.text-success {font-weight: 700; font-size: 15px;}
		.date {width: 55px; font-size: 15px; font-weight: 500;}
		.duration {width: 85px; font-size: 15px; font-weight: 500;}
	}
	.cost {width: 25%; padding-top: 30px; display: flex; font-weight: 500; font-size: 15px;
		.type {width: 100px;}
		.text-success {font-weight: 700; font-size: 15px;}
	}
	.btn-view {position: absolute; bottom: 0px; right: 0px; padding: 7px; font-size: 24px; color: $white;
		i {position: relative; z-index: 2; transition: all 0.3s ease-in-out;}
		&:after {content: ""; background-color: $success; position: absolute; width: 120px; height: 120px; bottom: -82px; right: -50px; transform: rotate(-37deg); transition: all 0.3s ease-in-out;}
		&:hover {
			i {transform: scale(1.1);}
			&:after {background-color: darken($success, 8%);}
		}
	}
	.btndiv {margin: 0px 0 0 0; position: absolute;right: 15px;top: 15px;
		.deletecard2 {width: 35px; height: 35px; line-height: 35px; font-size: 18x; text-align: center; color: $secondary; box-shadow: 0 0 6px #c0c0c0; border-radius: 4px; display: inline-block; background: $white; border: none; padding: 0px; margin-left: 10px;}
	}
} // Booking box css end

.booking-box.public {display: block !important;
	h3 {font-size: 15px; font-weight: 700; color: $secondary; margin: 0 0 15px 0;
		a {color: $secondary; text-decoration: none;}
	}
	.text-truncate {overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
	.content {font-size: 15px; color: #AAA7A7;}
	.time-group {width: 100%; padding-top: 20px;
		.duration.group {width: 115px;}
	}
}

.Left-Side {
	.userdatail {
		.profileimage {
			label.btn {font-size: 11px; margin-left: 10px; white-space: nowrap; padding: 0 0; background: none; border: none;}
		}
	}
}

.custom-modal {
	.modal-dialog {max-width: 600px; min-width: 600px; background-color: $black; padding: 60px 50px 50px; overflow: hidden;
		&:before {content: ""; width: 220px; height: 220px; background-color: #FE9E2D; position: absolute; top: -100px; left: -130px; transform: rotate(40deg);}
		.modal-content {margin-top: 10px; border: none;}
		.modal-header {margin-top: -60px; padding-left: 0px; padding-right: 0px; border: none; color: $white;}
		.modal-header .close {text-shadow: none; color: $white; margin: -30px -45px 0px 0px; opacity: 1;}
		.modal-body {border: none;}
	}
	mat-calendar-header {
		.mat-calendar-header {padding-top: 0px;}
		.mat-calendar-controls {padding-top: 0px;}
	}
}