/* You can add global styles to this file, and also import other style files */
@import "sass/variables";
@import "sass/custom";
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import "sass/ladda.scss";
@import "~@ng-select/ng-select/scss/default.theme";
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.1/animate.min.css";

// @font-face {font-family: 'AvenirLTStd-Book';src: url('/assets/fonts/AvenirLTStd-Book.eot');src: url('/assets/fonts/AvenirLTStd-Book.woff') format('woff'), url('/assets/fonts/AvenirLTStd-Book.ttf') format('truetype'), url('/assets/fonts/AvenirLTStd-Book.svg') format('svg');font-weight: normal;font-style: normal;}


a{outline: none;}
a:focus{outline: none;}
a:hover {outline: none;transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out;}
img{max-width:100%;}
.clearfix {clear: both;}
body, .mat-body, .mat-body-1, .mat-typography { font-family: 'Montserrat', sans-serif !important; line-height: 26px; background-color: transparent !important; margin: 0;} 
html, body { height: 100%; }
h1, h2, h3, h4, h5, h6 {font-family: 'Montserrat', sans-serif !important; line-height: 1.2 !important;}
@media (min-width: 1200px) { .container-fluid { max-width: 100%;} .container {max-width: 1170px;}}
.ngb-toasts { top: inherit !important; right: -12px !important; bottom: 10px !important; font-family: Arial, Helvetica, sans-serif;}
.ngb-toasts .toast-header {background: none; border-bottom: 0px solid transparent; color: #fff;font-size: 13px;font-weight: 400; padding: 15px 20px; line-height: 18px;}
.ngb-toasts .toast-header strong {font-weight: 700; font-family: Arial, Helvetica, sans-serif; padding: 0 15px 0 0;}
.ngb-toasts .toast-header .close {padding: 0px 5px;margin-left: auto;margin-bottom: inherit;background: #fff;width: 20px;height: 20px;font-size: 17px;border-radius: 100%;opacity: 1;text-align: center;line-height: 20px;}
.ngb-toasts .toast-body {display: none;}
.mat-error {font-family: Arial, Helvetica, sans-serif; font-size: 12px;}

ks-modal-gallery {background-color: rgba($black, 0.9);}

.header {padding: 0 0 0 0; min-height: 116px; box-shadow: 0 0 10px #c0c0c0;
    .container {max-width: 1545px; margin: 0 0 0 auto; padding: 0;}
    .row {margin: 0 0 0 0;}
    .col-sm-2, .col-sm-10 {padding: 0;}
    .logo {padding: 7px 0 0 0; width: 233px;}
    .header-top {border-bottom: 1px solid $primary; min-height: 42px; padding: 5px 100px 0 0; text-align: right;
        .sociel-media {display: inline-block;
            a {font-size: 13px; color: $secondary; text-decoration: none; margin: 0 40px 0 0; font-weight: 500; vertical-align: top;
                i {font-size: 18px; vertical-align: middle; margin: 0 9px 0 0;}
                span {font-weight: 700;}
            }
        }
        .phone {display: inline-block;
            a {font-size: 13px; color: $secondary; text-decoration: none; margin: 0 0px 0 0; font-weight: 500;
                img {margin: 0 15px 0 0;}
            }
        }
    }
    .header-bottom {
        .mainmenu {float: left;
            .navbar-expand-lg {
                .navbar-nav {
                    .nav-item {
                        .nav-link {font-size: 15px; color: $secondary; text-transform: uppercase; font-weight: 500; padding: 16px 20px; display: inline-block;} 
                        .nav-link:hover {color: $primary;}
                    }
                    .nav-item:first-child {
                        .nav-link {padding-left: 0px;}
                    }
                    .dropdown-toggle::after {border-top: 0.5em solid;border-right: 0.5em solid transparent;border-bottom: 0;border-left: 0.5em solid transparent;}
                    .dropdown-item {font-size: 15px;}
                }
            }
        }
        .header-right {float: right;}
        .login-menu {display: inline-block; margin: 25px 30px 0 0;
            a {font-size: 15px; color: $secondary; text-transform: uppercase; font-weight: 500; padding-right: 10px; padding-left: 10px; text-decoration: none;}
            a:hover {color: $primary;}
        }
        .headersearch {width: 75px; height: 74px; background: $primary; display: inline-block;margin: 0px 0px 0 0; vertical-align: top; padding: 22px 0 0 23px;
            .btn-search {padding: 0; background: none; border: 0px;}
        }
        /*.headersearch {width: 75px; height: 74px; background: $primary; display: inline-block;margin: 0px 0px 0 0; vertical-align: top; transform: skew(30deg); padding: 22px 0 0 23px;
            .btn-search {padding: 0; background: none; border: 0px; transform: skew(-29deg);
                img {transform: skew(-29deg);}
            }
        }*/
        .menulink {margin: 0 0 0 -12px;width: 275px; background: $primary; color: $white; display: inline-block; vertical-align: top; height: 74px; background: url("/assets/images/header-1.png") left center no-repeat; line-height: 74px; padding: 0 0 0 50px;
            a {font-size: 16px; color: $white; text-transform: uppercase; text-decoration: none;}
            a:hover {color: $yellow;}
        }
        .logged-menu {display: inline-block; margin: 25px 30px 0 0;
            .nav-link {font-size: 15px; color: $secondary; text-transform: uppercase; font-weight: 500; padding: 0px; text-decoration: none; cursor: pointer;}
            .dropdown-toggle::after {border-top: 0.5em solid;border-right: 0.5em solid transparent;border-bottom: 0;border-left: 0.5em solid transparent;vertical-align: middle;}
            .dropdown-item {font-size: 14px; font-weight: 500;}
            .dropdown-menu {left: -25px !important;}
        }
    }
}

.home-banner {position: relative;
    video {object-fit: cover; height: 755px;}
    .videotext {background-color: rgba(0, 0, 0, 0.4); position: absolute;margin-left: auto;margin-right: auto; top: 0px; left: 0px; width: 100%; height: 755px;
        h1 {font-size: 35px; font-weight: 700; text-transform: uppercase; color: $white; margin: 250px 0 25px 0; text-align: center; letter-spacing: 0.08em;}
        h3 {font-size: 22px; font-weight: 500; color: $white; margin: 0 0 50px 0; text-align: center;}
        .btndiv {text-align: center;
            .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 38px;box-shadow: none;}
            .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
        }
    }
    .owl-nav {display: none;}
    .owl-theme .owl-dots {position: absolute; width: 100%; margin: -42px 0 0 0;}
    .owl-theme .owl-dots .owl-dot span {width: 12px; height: 12px; margin: 5px 5px; background: $white;}
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {background: #8ABB2A;}
    .slide.one {
        .caption {position: absolute; z-index: 1; bottom: 25px; left: 75px; width: 490px;
           h1 {font-size: 35px; font-weight: 600; text-transform: uppercase; color: $secondary; line-height: 50px !important; margin: 0 0 15px 0; font-style: italic;} 
           .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 38px;box-shadow: none;}
           .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
        }
    }
    .slide.two {
        .caption {position: absolute; z-index: 1; top: 200px; right: 97px; width: 793px;
           h1 {font-size: 45px; font-weight: 600; color: $white; text-shadow: 0 0 6px #2d2d2d; line-height: 75px !important; margin: 0 0 40px 0;} 
           .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 38px;box-shadow: none;}
           .btn-primary:hover {background: #592a7b; border-color: #592a7b; color: $white;}
        }
    }
    .slide.three {
        .caption {position: absolute; z-index: 1; top: 180px; left: 357px; width: 876px;
           h1 {font-size: 45px; font-weight: 600; color: $white; text-shadow: 0 0 6px #2d2d2d; line-height: 75px !important; margin: 0 0 40px 0;} 
           .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 38px;box-shadow: none;}
           .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
        }
    }
    .slide.four {
        .caption {position: absolute; z-index: 1; top: 100px; left: 357px; width: 831px;
           h1 {font-size: 45px; font-weight: 600; color: $secondary; line-height: 75px !important; margin: 0 0 40px 0;} 
           .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 38px;box-shadow: none;}
           .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
        }
    }
}

.home-howitwork {padding: 80px 0 80px 0;
    .container {max-width: 1430px;}
    h1 {font-size: 40px; font-weight: 700; color: $secondary; margin: 0 0 80px 0; text-align: center;}
    .hiwbox.one {margin: 48px 0 0 0;
        .count {font-size: 80px; font-weight: 700; color: #CBCBCB; text-align: right; margin: 0 80px 5px 0;}
        .image {text-align: center; margin: 0 0 60px 0;}
        .content {text-align: center; font-size: 20px; font-weight: 700; color: $secondary; line-height: 33px; padding: 0 70px;}
    }
    .hiwbox.two {margin: 36px 0 0 0;
        .count {font-size: 80px; font-weight: 700; color: #CBCBCB; text-align: center; margin: 0 0px 12px 35px;}
        .image {text-align: center; margin: 0 0 45px 0;}
        .content {text-align: center; font-size: 20px; font-weight: 700; color: $secondary; line-height: 33px; padding: 0 0px;}
    }
    .hiwbox.three {margin: 15px 0 0 0;
        .count {font-size: 80px; font-weight: 700; color: #CBCBCB; text-align: center; margin: 50px 0px 0px 35px; position: absolute;}
        .image {text-align: center; margin: 0 0 60px 0;}
        .content {text-align: center; font-size: 20px; font-weight: 700; color: $secondary; line-height: 33px; padding: 0 0px;}
    }
    .hiwbox.four {
        .count {font-size: 80px; font-weight: 700; color: #CBCBCB; text-align: left; margin: 0 0px 20px 80px;}
        .image {text-align: center; margin: 0 0 50px 0;}
        .content {text-align: center; font-size: 20px; font-weight: 700; color: $secondary; line-height: 33px; padding: 0 15px;}
    }
}

.home-tryitout {padding: 0px 0 50px 0;
    .container {max-width: 1200px;}
    h1 {font-size: 40px; font-weight: 700; color: $secondary; margin: 0 0 50px 0; text-align: center;}
    .content {font-size: 20px; font-weight: 500; text-align: center; margin: 0 0 50px 0;}
    .trybox {width: 370px; height: 200px; background: url("/assets/images/tryitout-bg.png") left center no-repeat; margin: 0 0 30px 0;
        .image {text-align: right; padding: 11px 10px 11px 11px;
            img {width: 208px; height: 178px; object-fit: cover; object-position: center;}
        }
        .boxcontent {background: url("/assets/images/tryitout-info-bg.png") left center no-repeat; width: 201px; height: 178px; position: absolute; margin: 11px 0 0 11px; padding: 18px 0 0 12px;
            .time {font-size: 13px; font-weight: 500; color: $secondary; line-height: 21px; margin: 0 0 10px 0;}
            .catname {font-size: 15px; font-weight: 700; color: #8ABB2A; line-height: 21px; margin: 0 0 10px 0;}
            .uname {font-size: 13px; font-weight: 500; color: $secondary; line-height: 21px; margin: 0 0 10px 0;}
            .btndiv {
                .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 30px;font-size: 12px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 30px;box-shadow: none;}
                .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
            }
        }
    }
}

.home-disciplines {padding: 95px 0 0px 0; background: url("/assets/images/disciplines.png") left center no-repeat; background-size: cover; min-height: 931px;
    h1 {font-size: 40px; font-weight: 700; color: $secondary; margin: 0 0 0px 0; text-align: center;}
}

.home-bottom-banner {position: relative;
    .owl-nav {display: none;}
    .owl-theme .owl-dots {position: absolute; width: 100%; margin: -42px 0 0 0;}
    .owl-theme .owl-dots .owl-dot span {width: 12px; height: 12px; margin: 5px 5px; background: $white;}
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {background: #8ABB2A;}
    .slide.one {
        .caption {position: absolute; z-index: 1; bottom: 25px; left: 75px; width: 490px;
           h1 {font-size: 35px; font-weight: 600; text-transform: uppercase; color: $secondary; line-height: 50px !important; margin: 0 0 15px 0; font-style: italic;} 
           .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 38px;box-shadow: none;}
           .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
        }
    }
    .slide.two {
        .caption {position: absolute; z-index: 1; top: 200px; right: 97px; width: 793px;
           h1 {font-size: 45px; font-weight: 600; color: $white; text-shadow: 0 0 6px #2d2d2d; line-height: 75px !important; margin: 0 0 40px 0;} 
           .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 38px;box-shadow: none;}
           .btn-primary:hover {background: #592a7b; border-color: #592a7b; color: $white;}
        }
    }
    .slide.three {
        .caption {position: absolute; z-index: 1; top: 180px; left: 357px; width: 876px;
           h1 {font-size: 45px; font-weight: 600; color: $white; text-shadow: 0 0 6px #2d2d2d; line-height: 75px !important; margin: 0 0 40px 0;} 
           .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 38px;box-shadow: none;}
           .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
        }
    }
    .slide.four {
        .caption {position: absolute; z-index: 1; top: 100px; left: 357px; width: 831px;
           h1 {font-size: 45px; font-weight: 600; color: $secondary; line-height: 75px !important; margin: 0 0 40px 0;} 
           .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 38px;box-shadow: none;}
           .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
        }
    }
}

.home-whychoose {padding: 100px 0 100px 0;
    .container {max-width: 1600px;}
    h1 {font-size: 40px; line-height: 65px !important; font-weight: 700; color: $secondary; margin: 0 0 150px 0; text-align: left;}
    .content {font-size: 20px; font-weight: 500; line-height: 35px; text-align: left; margin: 0 0 210px 0;}
    .btndiv {
        .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 38px;box-shadow: none;}
        .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
    }
}

.home-victorystories {padding: 100px 0 100px 0;
    h1 {font-size: 40px; font-weight: 700; color: $secondary; margin: 0 0 40px 0; text-align: center;}
    .content {font-size: 25px; font-weight: 300; line-height: 45px; text-align: center; margin: 0 0 60px 0; padding: 0 65px;}
    .victoryslider {max-width: 770px; margin: 0 auto; box-shadow: 0 0 15px #9e9e9e; padding: 25px;
        .vtsbox {
            .image {width: 308px; height: 327px; float: left; margin: 0 30px 0 0;}
            .detail {
                h3 {font-size: 25px; font-weight: 700; color: $secondary; margin: 0 0 30px 0; line-height: 40px !important;}
                .comment {font-size: 15px; color: $secondary; line-height: 26px; font-weight: 500; min-height: 190px;}
                .vname {font-size: 15px; color: $secondary; line-height: 26px; font-weight: 500; text-align: right;}
            }
        }
        .owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {width: 30px; height: 53px; border-radius: 0; position: absolute; top: 43%;}
        .owl-carousel .owl-nav .owl-next {background: url("/assets/images/v-next.png") left center no-repeat; right: -110px;}
        .owl-carousel .owl-nav .owl-prev {background: url("/assets/images/v-prev.png") left center no-repeat; left: -110px;}
    }
}

.home-areyoucoach {padding: 150px 0 0px 0; background: url("/assets/images/areyoucoach.png") left center no-repeat; background-size: cover; min-height: 966px;
    h1 {font-size: 60px; font-weight: 700; color: $white; margin: 0 0 36px 0; text-align: left; line-height: 80px !important;}
    .btndiv {
        .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 28px;box-shadow: none;}
        .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
    }
}

.footer {background: url("/assets/images/Footer.png") left center no-repeat; background-size: cover; padding: 50px 0 50px 0; min-height: 560px;
    .image {margin: 0 0 25px 0; text-align: center;}
    .footerinfo {
        .content {font-size: 15px; line-height: 28px; text-align: center; color: $secondary; padding: 0 0px 0 0;}
    }
    .footer-address {padding: 0 0 0 8px;
        ul {padding: 0; margin: 0px; list-style: none;
            li {font-size: 15px; color: $secondary; margin-bottom: 15px;
                a {color: $secondary;}
                img {margin: 0 10px 0 0;}
            }
        }
    }
    .sociel-media {text-align: center; margin: 20px 0 45px 0;
        a {width: 39px; height: 39px; margin: 0 5px; display: inline-block; font-size: 18px; color: $secondary; text-align: center; line-height: 39px; background: url("/assets/images/sociel-bg.png") left center no-repeat;}
        a:hover {color: $primary;}
    }
    .copymenu {color: $secondary; font-size: 13px; border-top: 1px solid #B5B5B5; border-bottom: 1px solid #B5B5B5; padding: 12px 0; text-align: center;
        a {font-size: 13px; font-weight: 500; color: $secondary; text-decoration: none; padding: 0 7px;}
        a:hover {color: $primary;}
    }
    .copyright {font-size: 12px; color: #757575; text-align: center; padding: 15px 0 0 0;}
}

.aboutus-page {
    .dontwish {background: url("/assets/images/about-1.png") left center no-repeat; background-size: cover; min-height: 750px;
        h1 {font-size: 45px; font-weight: 900; text-transform: uppercase; color: $white; margin: 115px 0 20px 0; line-height: 60px !important;
            span {color: #FE9E2D;}
        }
        .content {font-size: 20px; line-height: 36px; color: $white; font-weight: 600; margin: 0 0 33px 0; padding: 0 40px 0 0;}
        .btndiv {
            .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 38px;box-shadow: none;}
            .btn-primary:hover {background: $white; border-color: $white; color: $secondary;}
        }
    }
    .aboutreakt {padding: 80px 0px;
        h1 {font-size: 45px; font-weight: 900; color: $secondary; margin: 0px 0 30px 0; line-height: 45px !important;}
        .content {font-size: 20px; line-height: 40px; color: $secondary; font-weight: 400; margin: 0 0 0px 0; padding: 0 0px 0 0;}
    }
    .bestronger {padding: 0px 0px;
        h1 {font-size: 45px; font-weight: 900; color: $secondary; margin: 60px 0 30px 0; line-height: 45px !important;}
        h2 {font-size: 35px; font-weight: 600; color: $primary; margin: 0px 0 20px 0; line-height: 45px !important;}
        .content {font-size: 20px; line-height: 40px; color: $secondary; font-weight: 400; margin: 0 0 0px 0; padding: 0 0px 0 0;}
        .image {
            img {max-width: 532px;}
        }
    }
    .whychooseus {padding: 140px 0px 100px 0; text-align: center; background: url("/assets/images/about-3.png") left bottom repeat-x;
        h1 {font-size: 40px; font-weight: 700; color: $secondary; margin: 0px 0 110px 0; line-height: 45px !important;}
        h3 {font-size: 15px; font-weight: 500; color: $secondary; margin: 0px 0 25px 0; line-height: 18px !important; border-bottom: 3px solid $primary; padding: 0 0 8px 0; display: inline-block;}
        .wcubox {padding: 20px 5px; background: $primary;
            .image {width: 113px; height: 113px; background: $white; border-radius: 100%; text-align: center; line-height: 113px; margin: -75px auto 20px auto;}
            .title {font-size: 20px; color: $white; line-height: 29px; margin: 18px; margin: 0 0 15px 0;}
            .content {font-size: 14px; line-height: 24px; color: #a182b8; font-weight: 400; margin: 0 0 0px 0; padding: 0 15px;}
        }
        .wcubox.two {background: #8ABB2A;
            .content {color: #b9d67f;}
        }
        .wcubox.three {background: #FE9E2D;
            .content {color: #fec581;}
        }
        .wcubox.four {background: $secondary;
            .content {color: #7a7a7a;}
        }
    }
    .whatweoffer {padding: 260px 0px 0px 0; background: url("/assets/images/about-4.png") right center no-repeat; min-height: 883px;
        h1 {font-size: 40px; font-weight: 700; color: $secondary; margin: 0px 0 25px 0; line-height: 45px !important;}
        h3 {font-size: 15px; font-weight: 500; color: $secondary; margin: 0px 0 25px 0; line-height: 18px !important; border-bottom: 3px solid $primary; padding: 0 0 8px 0; display: inline-block;}
        .content {font-size: 15px; line-height: 28px; color: $secondary; font-weight: 400; margin: 0 0 0px 0; padding: 0 60px 0 0;}
        .servicebox {background: $primary; padding: 50px; max-width: 370px;
            ul {padding: 0px; margin: 0px; list-style: none;
                li {margin: 0 0 30px 0; font-size: 15px; color: $white;
                    img {margin: 0 18px 0 0;}
                }
                li:last-child {margin: 0px;}
            }
        }
    }
}

.contactus-page {
    .contact-banner {
        img {width: 100%;}
    }
    .contact-relative {position: relative;}
    .contact-detail {border-radius: 4px; padding: 50px 50px; background: $white; box-shadow: 0 0 6px #c0c0c0; margin: -175px 0 50px 0; position: absolute;
        h1 {font-size: 40px; font-weight: 700; color: $secondary; margin: 0px 0 30px 0; line-height: 70px !important; text-align: center;}
        .content {font-size: 20px; color: $secondary; font-weight: 400; margin: 0 0 40px 0; padding: 0 0px 0 0; text-align: center;}
        .condiv {text-align: center; font-size: 13px; color: $secondary;
            .call {display: inline-block; margin: 0 150px 0 0;
                img {width: 25px; margin: 0 15px 0 0;}
            }
            .email {display: inline-block; margin: 0 150px 0 0;
                img {width: 25px; margin: 0 15px 0 0;}
            }
            .address {display: inline-block;
                img {width: 25px; margin: 0 15px 0 0;}
            }
        }
    }
    .contact-form {padding: 230px 0 50px 0; max-width: 770px; margin: 0 auto;
        .form-group {position: relative;margin-bottom: 40px;}
        .form-control {font-size: 15px;border: none;border-bottom: 1px solid #BBBBBB;height: 44px;border-radius: 0px;color: $secondary; box-shadow: none !important; background: none; padding: 0px 0 0 26px;}
        .form-control-placeholder {position: absolute;top: 12px;padding: 0px 0 0 26px;transition: all 200ms;font-size: 15px;font-weight: 500;z-index: -1;}
        .form-control:focus + .form-control-placeholder,
        .form-control:valid + .form-control-placeholder {font-size: 12px;transform: translate3d(0, -120%, 0);color: #8ABB2A; font-weight: 500;}
        .form-control.email {background: url("/assets/images/svg/email-2.svg") left center no-repeat;}
        .form-control.user {background: url("/assets/images/svg/user.svg") left center no-repeat;}
        .form-control.phone {background: url("/assets/images/svg/phone-call-2.svg") left center no-repeat;}
        .form-control.subject {background: url("/assets/images/svg/fitness.svg") left center no-repeat;}
        .form-control.message {background: url("/assets/images/svg/email.svg") left center no-repeat;}
        .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 60px;box-shadow: none;}
        .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
    }
}

.faq-page {padding: 0 0 50px 0;
    .faq-search {padding: 170px 0px 0px 0; background: url("/assets/images/faq-1.png") left center no-repeat; background-size: cover; min-height: 500px;
        h1 {font-size: 39px; font-weight: 700; color: $white; margin: 0px 0 35px 0; line-height: 60px !important; text-align: center;}
        .form-control {font-size: 15px;border: none;border-bottom: 0px solid #BBBBBB;height: 60px;border-radius: 30px; border-top-left-radius: 0px; border-bottom-left-radius: 0px; color: $secondary; box-shadow: none !important; background: $white; padding: 0px 0 0 0px;}
        .btn-outline-secondary {color: $white;background-color: $white !important;border-color: $white !important;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; border-top-right-radius: 0px; border-bottom-right-radius: 0px; text-transform: uppercase;padding: 0 30px;box-shadow: none !important;}
    }
    .faq-bottom {padding: 90px 0 0 0;
        .faq-left {border: 1px solid #D5D4D4; border-radius: 8px;
            .fl-header {font-size: 25px; font-weight: 700; color: $secondary; margin: 0px 0 0px 0; line-height: 45px !important; text-align: center; padding: 25px 0px;}
            .fl-body {
                ul {padding: 0; margin: 0px; list-style: none;
                    li {
                        a {font-weight: 500;font-size: 15px; line-height: 24px; color: $secondary; padding: 30px; display: block; border-top: 1px solid #D5D4D4; border-left: 5px solid transparent; text-decoration: none;}
                    }
                    li.active {
                        a {border-left: 5px solid $primary; color: $primary;}
                    }
                }
            }
            .fl-footer {padding: 25px 0; text-align: center; border-top: 1px solid #D5D4D4;
                .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 40px;box-shadow: none;}
                .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
                h5 {font-size: 15px; font-weight: 500; color: $secondary; margin: 12px 0 0 0; text-transform: uppercase;}
            }
        }
        .faq-right {
            .accordion {
                .card {border: none; border-bottom: 1px solid #D5D4D4; border-radius: 0px;
                    .card-header {padding: 20px 30px; background: $white;
                        .btn-link {padding: 0; box-shadow: none; text-decoration: none;
                            h5 {margin: 0 0; font-size: 15px; color: $secondary; font-weight: 600; text-decoration: none;}
                        }
                    }
                    .card-body {padding: 15px 30px; font-size: 15px; color: #747474; line-height: 28px;}
                }
            }
        }
    }
}

.content-page {padding: 50px 0px;
    h1 {font-size: 30px; color: $secondary; font-weight: 700; margin: 0 0 20px 0;}
    .content {font-size: 15px; line-height: 28px; color: #747474;
        p {margin: 0 0 30px 0 !important;}
        strong {color: $secondary;}
    }
}

.coach-listing {padding: 60px 0 50px 0;
    h1 {font-size: 25px; color: $secondary; margin: 0 0 20px 0; font-weight: 700;
        span.resultf {font-size: 12px; color: #AAA7A7; font-weight: 400; margin: 0 0 0 20px;}
    }
    .coach-box {box-shadow: 0px 0px 6px #d6d6d6; border-radius: 8px; padding: 20px 20px 20px 20px; overflow: hidden; position: relative; margin: 0 0 30px 0;
        .image {text-align: center; margin: 0 0 5px 0;
            img {width: 140px; height: 140px; border-radius: 100%; object-fit: cover; object-position: center;}
        }
        .rating {text-align: center;
            ngb-rating {font-size: 24px;margin-right: 0px;color: #622F88;vertical-align: top;}
        }
        .detail {margin: 0 0 0 -10px;
            .catename {display: inline-block; font-size: 15px; color: $secondary; font-weight: 700; margin: 0 200px 0 0;}
            .price {display: inline-block; font-size: 15px; color: $secondary; font-weight: 700;}
            .content {font-size: 15px; color: #AAA7A7; line-height: 24px; margin: 10px 0 15px 0;}
            .groupclass {
                .linkclass {font-size: 15px; color: $secondary; margin: 0 0 20px 0; }
                .group {background: #F1DFFE; font-size: 13px; color: $secondary; padding: 2px 15px; display: inline-block; margin: 0px 12px 12px 0; border-radius: 30px;}
            }
        }
        h3 {font-size: 20px; color: $secondary; margin: 0 0 12px 0;}
        .address {font-size: 15px; color: $secondary;
            img {margin: 0 10px 0 0;}
        }
        .btn-view {position: absolute; bottom: 0px; right: 0px; padding: 7px; font-size: 24px; color: $white;
            i {position: relative; z-index: 2; transition: all 0.3s ease-in-out;}
            &:after {content: ""; background-color: $success; position: absolute; width: 120px; height: 120px; bottom: -82px; right: -50px; transform: rotate(-37deg); transition: all 0.3s ease-in-out;}
            &:hover {
                i {transform: scale(1.1);}
                &:after {background-color: darken($success, 8%);}
            }
        }
    }
    .refinesearch {border: 1px solid #CBCBCB; padding: 20px; border-radius: 8px; margin: 50px 0 0 0;
        h1 {font-size: 15px; color: $secondary; margin: 0 0 0px 0; font-weight: 700; padding: 0 0 15px 0; border-bottom: 1px solid #CBCBCB; text-transform: uppercase;}
        .searchkeword {padding: 25px 0; border-bottom: 1px solid #CBCBCB;
            .form-control {font-weight: 400;font-size: 14px;border: 1px solid #D9D8D8;height: 50px;border-top-left-radius: 30px;border-bottom-left-radius: 30px;box-shadow: none;color: $secondary;}
            .btn-outline-secondary {border-color: #D9D8D8; border-top-right-radius: 30px;border-bottom-right-radius: 30px; background: $white !important; box-shadow: none !important; line-height: 33px;}
        }
        .classes {border-bottom: 1px solid #CBCBCB; padding: 20px 0 10px;
            h3 {font-size: 15px; color: $secondary; margin: 0 0 10px 0; font-weight: 700; text-transform: uppercase;}
            .custom-control-inline {display: block; margin-bottom: 8px;}
            .custom-control-label {font-size: 15px; font-weight: 300; padding-left: 9px; color: $secondary;}
            .custom-control-label::after, .custom-control-label::before {width: 19px;height: 19px;top: 4px;}
            .custom-control-label::before {border-color: #8ABB2A;}
            .custom-control-input:checked ~ .custom-control-label::before {background-color: #8ABB2A;}
        }
        .language {border-bottom: 1px solid #CBCBCB; padding: 20px 0 20px;
            h3 {font-size: 15px; color: $secondary; margin: 0 0 10px 0; font-weight: 700; text-transform: uppercase;}
            .select {position: relative;background: white;}
            .select:after {content: '';position: absolute;top: -2px;width: 0;height: 0;right: 20px;bottom: 0;margin: auto;border-style: solid;border-width: 7px 7px 0 7px;border-color: #000000 transparent transparent transparent;pointer-events: none;}
            .select:before {width: 30px;position: absolute;top: 1px;right: 1px;bottom: 1px;background: none;content: '';pointer-events: none;}
            .select:hover:before {background: none;}
            .select select {font-size: 14px;border: none;box-shadow: none;border-radius: 30px;background: #ffffff;height: 100%;width: 100%;cursor: pointer;outline: none;padding-right: 35px;padding-left: 15px;border: 1px solid #D9D8D8;-moz-appearance: none;-webkit-appearance: none;color: $secondary !important;height: 50px;}
            .select select:-moz-focusring {color: transparent;}
            .select select::-ms-expand {display: none;}
            .form-control {font-weight: 400;font-size: 14px;border: 1px solid #D9D8D8;height: 50px;border-radius: 30px;box-shadow: none;color: $secondary;}
            .rating {
                ngb-rating {font-size: 35px;margin-right: 0px;color: #622F88;vertical-align: top;
                    span {margin: 0 8px 0 0;}
                }
            }
            .ngx-slider .ngx-slider-bubble {font-size: 15px; color: $secondary;
                b {display: none;}
            }
            .ngx-slider .ngx-slider-pointer {background-color: $primary;}
            .ngx-slider .ngx-slider-selection {background: $primary;}
            .ngx-slider .ngx-slider-pointer.ngx-slider-active::after {background: #8ABB2A;}
        }
        .language.overallrating {border: none; padding-bottom: 0px;}
    }
}

.coach-individual {padding: 0 0 50px 0;
    .ci-coverimage {
        img {height: 400px;width: 100%;object-fit: cover;object-position: center;}
    }
    .row.main {margin: 0 -10px;}
    .col-sm-4.main, .col-sm-8.main {padding: 0 10px;}
    .ci-left {background: $primary; padding: 20px;
        .ch-image {text-align: center; margin: -65px 0 0 0;
            img {border: 4px solid $white; border-radius: 100%; width: 140px; height: 140px; object-fit: cover; object-position: center;}
        }
        h2 {font-size: 20px; color: $white; margin: 25px 0 15px 0; text-align: center;}
        .address {font-size: 15px; color: #b197c4; text-align: center; margin: 0 0px 15px 0; font-weight: 500;
            img {margin: 0 10px 0 0;}
        }
        .linkgroup {font-size: 15px; color: $white; text-align: center; font-weight: 500;}
        .prices {font-size: 15px; font-weight: 500; color: $white; text-align: center; border-bottom: 1px solid #6b3692; border-top: 1px solid #6b3692; padding: 15px 0px; margin: 20px 0 30px;
            .oneprice {margin: 0 0 10px 0;}
        }
        .btndiv {
            .btn-primary {color: $primary;background-color: $white;border-color: $white;line-height: 50px;margin: 0 0 30px 0;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 38px;box-shadow: none; display: block; width: 100%;}
            .btn-primary:hover {background: #8ABB2A; border-color: #8ABB2A; color: $white;}
            .btn-info {color: $white; background-color: #FE9E2D;border-color: #FE9E2D;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 38px;box-shadow: none; display: block; width: 100%;}
            .btn-info:hover {background: #8ABB2A; border-color: #8ABB2A; color: $white;}
        }
        .aboutme {border-top: 1px solid #6b3692; border-bottom: 1px solid #6b3692; padding: 30px 0; margin: 30px 0 0 0;
            h3 {font-size: 20px; font-weight: 700; color: $white; margin: 0px 0 15px 0;}
            .content {font-size: 15px; color: #ac90c0; line-height: 28px; margin: 0px 0 0 0; font-weight: 500;}
        }
        .sociel-media {padding: 20px 0 0 0;
            a {font-size: 18px; color: $white; margin: 0 15px 0 0;}
            a:hover {color: #8ABB2A;}
        }
    }
    .ci-right {padding: 0 0 0 0px;
        .ci-rating-box {margin: 30px 0 0 0;
            h2 {font-size: 25px; font-weight: 700; color: $secondary; margin: 0 0 20px 0; padding: 0 0 20px 0; border-bottom: 1px solid #D5D4D4; position: relative;
                .rating {position: absolute; top: -2px; left: 290px;
                    ngb-rating {font-size: 24px;margin-right: 10px;color: #622F88;vertical-align: middle;}
                    small {font-size: 12px; color: #AAA7A7;}
                }
            }
            h2:before {content: ""; width: 240px; height: 1px; background: #b097c3; position: absolute; bottom: -1px;}
            .commentbox {border: 1px solid #D5D4D4; border-radius: 8px; padding: 30px 20px 10px 20px;
                .commbox {
                    .image {float: left; margin: 0 20px 0 0;
                        img {display: block; width: 60px; height: 60px; border-radius: 100%;}
                    }
                    h3 {font-size: 15px; font-weight: 600; color: #E44743; margin: 0 0 5px 0; text-transform: uppercase;}
                    .date {font-size: 13px; color: #8A8A8A; display: inline-block; margin: 0 25px 0 0;}
                    .rating {display: inline-block;
                        ngb-rating {font-size: 24px;margin-right: 10px;color: #622F88;vertical-align: middle;}
                        small {font-size: 12px; color: #AAA7A7;}
                    }
                    .comment {font-size: 15px; color: #747474; line-height: 28px; margin: 10px 0 0 0;}
                }
                .owl-theme .owl-dots {margin: 20px 0 0 0;}
                .owl-theme .owl-dots .owl-dot span {width: 12px; height: 12px; margin: 5px 5px; background: $secondary;}
                .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {background: #8ABB2A;}
            }
        }
        .ci-expertise-box {margin: 30px 0 0 0;
            h2 {font-size: 25px; font-weight: 700; color: $secondary; margin: 0 0 20px 0; padding: 0 0 20px 0; border-bottom: 1px solid #D5D4D4; position: relative;}
            h2:before {content: ""; width: 240px; height: 1px; background: #b097c3; position: absolute; bottom: -1px;}
            .expertisebox {border: 1px solid #D5D4D4; border-radius: 8px; padding: 20px 20px 0px 20px;
                .row {margin: 0 -10px;}
                .col-sm-6 {padding: 0 10px;}
                .expbox {border: 1px solid #D5D4D4; border-radius: 8px; padding: 10px; margin-bottom: 20px;
                    .btn-image {width: 100%; border-radius: 8px;}
                    .image {position: relative;
                        img {height: 225px; width: 100%; max-width: 100%; object-fit: cover; object-position: center; border-radius: 8px;}
                        .imgcount {position: absolute; top: 0px; right: 0px; width: 93px; height: 35px; border-top-right-radius: 8px; border-bottom-left-radius: 8px; background: $primary; font-size: 13px; color: $white; text-align: center; text-transform: uppercase; line-height: 35px;}
                    }
                    h3 {font-size: 15px; font-weight: 700; color: $secondary; margin: 10px 0 10px 0; text-transform: uppercase;}
                    .content {font-size: 15px; color: #6A6767; line-height: 26px; margin: 0px 0 5px 0;}
                }
            }
        }
        .ci-certification-box {margin: 30px 0 0 0;
            h2 {font-size: 25px; font-weight: 700; color: $secondary; margin: 0 0 20px 0; padding: 0 0 20px 0; border-bottom: 1px solid #D5D4D4; position: relative;}
            h2:before {content: ""; width: 180px; height: 1px; background: #b097c3; position: absolute; bottom: -1px;}
            .certificationbox {border: 1px solid #D5D4D4; border-radius: 8px; padding: 10px 20px 20px 20px;
                .certibox {border-bottom: 1px solid #D5D4D4; padding: 20px 0;
                    h3 {font-size: 15px; font-weight: 700; color: $primary; text-transform: uppercase; margin: 0 0 10px 0;}
                    .content {font-size: 15px; color: $secondary; text-transform: uppercase;}
                    .date {color: #6A6767; font-size: 15px; float: right; margin: -25px 0 0 0;}
                }
                .certibox:last-child {padding-bottom: 0px; border: 0px;}
            }
        }
    }
}

.login-page {
    .container-fluid {padding: 0px;}
    .row.main, .col-sm-6.main {margin: 0; padding: 0px;}
    .image {
        img {width: 100%; height: 100%;}
    }
    .logoimg {position: absolute; left: 0px; right: 0px; margin: 17px auto 0 auto; width: 233px; height: 100px;}
    .login-box {max-width: 356px; margin: 0px auto 0 auto;
        h1 {font-size: 40px; font-weight: 700; color: $secondary; margin: 100px 0 70px 0; text-align: center;}
        .form-group {position: relative;margin-bottom: 40px;}
        .form-group-1 {position: relative;margin-bottom: 30px;}
        .form-group-2 {position: relative;margin-bottom: 20px;}
        .form-control {font-size: 15px;border: none;border-bottom: 1px solid #BBBBBB;height: 44px;border-radius: 0px;color: $secondary; box-shadow: none !important; background: none; padding: 0px 0 0 26px;}
        .form-control-placeholder {position: absolute;top: 12px;padding: 0px 0 0 26px;transition: all 200ms;font-size: 15px;font-weight: 500;z-index: -1;}
        .form-control:focus + .form-control-placeholder,
        .form-control:valid + .form-control-placeholder {font-size: 12px;transform: translate3d(0, -120%, 0);color: #8ABB2A; font-weight: 500;}
        .form-control.email {background: url("/assets/images/svg/email-2.svg") left center no-repeat;}
        .form-control.password {background: url("/assets/images/svg/lock.svg") left center no-repeat;}
        .form-control.user {background: url("/assets/images/svg/user.svg") left center no-repeat;}
        .form-control.aboutus {background: url("/assets/images/svg/info.svg") left center no-repeat;}
        .custom-control {display: inline-block;}
        .custom-control-label {font-size: 13px;font-weight: 500;padding-left: 5px; color: $secondary;
            a {text-decoration: underline;}
          }
        .custom-control-label::after, .custom-control-label::before {width: 19px;height: 19px;top: 0px;}
        .custom-control-label::before {border: #8ABB2A solid 1px;}
        .custom-control-input:checked ~ .custom-control-label::before {border-color: #8ABB2A; background-color: #8ABB2A;}
        a.forgot {color: $primary;font-size: 13px; font-weight: 500; margin: 0px 0 0 0px; float: right; text-decoration: none;}
        a.forgot:hover {color: $secondary;}
        .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 65px;box-shadow: none;}
        .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
    }
    .loginsociel {text-align: left; margin: 0px 0 30px 0;
        a, button {width: 175px; height: 50px; box-shadow: none;background-color: #8ABB2A;border: 1px solid #8ABB2A;padding: 0 12px;border-radius: 30px;display: inline-block;margin: 0 20px 0 0px; color: $white;}
        a:hover, button:hover {background-color: #8ABB2A;border-color: #8ABB2A;}
    }
}

.login-page.register-page {
    .login-box {max-width: 760px; padding: 0px 0 0px 0;
      a {text-decoration: none; color: $primary; font-weight: 500;}
      .btn-primary {padding: 0 55px;}
      span.account {margin: 0 0 0 0px; font-size: 15px; font-weight: 500;}
    }
  }

.login-page.forgetpassword {
    .login-box {
      h1 {text-align: center; margin: 100px 0 65px 0;}
      .content {font-size: 13px; font-weight: 500; text-align: left; color: $secondary; margin: 0 0 20px 0px;}
      a.backlogin {font-size: 13px; font-weight: 500; color: $secondary; margin: 0 0 0 0px;}
      a.backlogin:hover {color: $secondary;}
      .btn-primary {padding: 0 60px;}
    }
  }

app-dashboard-layout {
    .header {box-shadow: 0px 3px 6px rgba($black, 0.2); position: relative;}
}
  
.dashboard-card {margin-top: -203px;
	.dash-card-title {color: $white; text-transform: uppercase; padding-bottom: 0px;
		h1 {font-weight: 600; margin: 0 0 30px;}
		ul {margin: 0px -40px 30px -40px; padding: 0px; list-style: none; display: flex; 
			li {padding: 0px 40px;}
			a {font-size: 25px; font-weight: 600; text-transform: uppercase; color: lighten($primary, 40%); padding-bottom: 0px; transition: all 0.3s ease-in-out; 
				&:hover, &.active {text-decoration: none; color: $white;}
			}
		}
	}
	.dash-card-body {background-color: $white; box-shadow: 0px 0px 17px rgba($black, 0.1); padding: 50px 50px 50px 50px; min-height: 725px;}
	.pagesubmenu {margin-bottom: 50px;
		ul {margin: 0px -25px 0px -25px; padding: 0px; list-style: none; display: flex; 
			li {padding: 0px 20px;}
			a {font-size: 20px; font-weight: 700; text-transform: uppercase; color: $secondary; border-bottom: 4px solid transparent; padding-bottom: 15px; transition: all 0.3s ease-in-out;
				&:hover, &.active {text-decoration: none; color: $primary; border-bottom-color: $primary;}
			}
		}
	}
}

// mat-form-field ng-tns-c160-9 mat-primary mat-form-field-type-mat-input mat-form-field-appearance-legacy mat-form-field-can-float mat-form-field-has-label ng-touched ng-dirty mat-form-field-invalid ng-invalid mat-form-field-hide-placeholder
// mat-form-field ng-tns-c160-13 mat-primary mat-form-field-type-mat-input mat-form-field-appearance-legacy mat-form-field-can-float mat-form-field-has-label ng-valid ng-dirty ng-touched mat-form-field-should-float

.update-profile {
    .crprbtn {padding: 20px 0 0 0;
        .btn-primary {color: $primary;background-color: $white;border-color: $primary;line-height: 40px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 20px;box-shadow: none; margin: 0 10px 0 0;}
        .btn-primary:hover {background: $primary; border-color: $primary; color: $white;}
    }
    textarea {resize: none !important;}
    .mat-form-field {font-family: "Montserrat", sans-serif !important;}
    .mat-form-field-hide-placeholder .mat-form-field-label {color: $secondary;}
    .mat-focused .mat-form-field-label, .mat-form-field-should-float .mat-form-field-label {color: #8ABB2A;}
    .mat-form-field-appearance-legacy .mat-form-field-underline {background-color: #BBBBBB;}
    .mat-form-field-appearance-legacy .mat-form-field-infix {padding: 10px 0;}
    .form-group {margin-bottom: 40px;}
    .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {background-color: #BBBBBB; background-image: none;}
    .mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon, .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {width: 24px;}
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {border-color: $primary;}
    .mat-radio-outer-circle {border-color: $primary;}
    .mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {background-color: $primary;}
    .mat-radio-label {margin-right: 60px;}
    .mat-radio-label-content {font-family: "Montserrat", sans-serif !important;}
    mat-label {font-size: 15px; font-size: 500;}
    h3.subtitle {font-size: 20px; font-weight: 700; margin: 0 0 10px 0; text-transform: uppercase; color: $secondary;}
    .btndiv {
        .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase; padding: 0 60px; box-shadow: none;}
        .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
        .btn-warning {color: $white; line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase; padding: 0 60px; box-shadow: none;}
        .btn-warning:hover {color: $white;}
    }
    .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {background-color: $success}
}

.transactions-page {
    .totalcon {font-size: 15px; font-weight: 700; color: #8ABB2A;
        span {font-size: 18px; margin: 0 0 0 15px;}
    }
    .table {margin-bottom: 0px;border-collapse: separate;border-spacing: 6px 20px;}
    .table thead th {vertical-align: middle; font-weight: 700; color: $primary; white-space: nowrap;border-top: 0px solid #CBCBCB;border-bottom: 0px solid #CBCBCB; background: $white; font-size: 15px; padding: 0px 20px 0px 20px;}
    .table tbody tr {box-shadow: 0 0 6px #d6d6d6; border-radius: 8px;}
    .table td {border-top: 0px solid #CBCBCB; padding: 15px 20px; color: $secondary; font-size: 15px; vertical-align: middle;}
}

.notification-page {
    .table {margin-bottom: 0px;border-collapse: separate;border-spacing: 6px 20px;}
    .table thead th {vertical-align: middle; font-weight: 700; color: $primary; white-space: nowrap;border-top: 0px solid #CBCBCB;border-bottom: 0px solid #CBCBCB; background: $white; font-size: 15px; padding: 0px 20px 0px 20px;}
    .table tbody tr {box-shadow: 0 0 6px #d6d6d6; border-radius: 8px;}
    .table td {border-top: 0px solid #CBCBCB; padding: 15px 20px; color: $secondary; font-size: 15px; vertical-align: middle;
        .btn-danger {color: $secondary;background-color: $white !important;border-color: transparent !important; box-shadow: 0 0 6px #d6d6d6 !important; width: 46px; height: 46px; padding: 0px; line-height: 44px;}
    }
}

.addresses {
    .btndiv {text-align: right;
        .btn-outline-success {color: $primary;background-color: $white;border-color: $primary; font-size: 15px; font-weight: 700; text-transform: uppercase; line-height: 50px; padding: 0 25px; box-shadow: none !important;
            i {font-size: 20px; vertical-align: text-bottom; margin: 0 7px 0 0;}
          }
          .btn-outline-success:hover {background-color: $primary;border-color: $primary; color: $white;}
    }
    
    .addess-box {
      .radio {font-size: 15px; color: $secondary;
        .MuiRadio-root {color: $primary;}
        .PrivateSwitchBase-root-1 {padding: 9px 9px 12px 0px;}
      }
      .form-check {margin-bottom: 10px;}
      .card {border-color: #CBCBCB;}
      .card-header {padding: 15px 30px !important;
        .action {float: right;
            .btn-default {box-shadow: 0 0 6px #dcdcdc;height: 42px;width: 42px;padding: 0;line-height: 38px;border: none;background: #fff; margin: 0 0px 0 10px;}
          }
    }
      .card-body {font-size: 15px; padding: 30px !important; min-height: auto;
        h5.card-title {font-size: 20px; color: $secondary; font-weight: 700;}
        .titletxt {margin: 0 15px 0 0; font-weight: 700;}
      }
    }
    .addess-box:last-child {
      .mb-5 {margin-bottom: 40px !important;}
    }
    .setting-form {
        .form-group {position: relative;margin-bottom: 40px;}
        .form-control {font-size: 15px;border: none;border-bottom: 1px solid #BBBBBB;height: 44px;border-radius: 0px;color: $secondary; box-shadow: none !important; background: none; padding: 0px 0 0 26px;}
        .form-control:disabled, .form-control[readonly] {color: #8ABB2A;}
        .form-control-placeholder {position: absolute;top: 12px;padding: 0px 0 0 26px;transition: all 200ms;font-size: 15px;font-weight: 500;z-index: 1;}
        .form-control:focus + .form-control-placeholder,
        .form-control:valid + .form-control-placeholder {font-size: 12px;transform: translate3d(0, -120%, 0);color: #8ABB2A; font-weight: 500;}
        .form-control.email {background: url("/assets/images/svg/email-2.svg") left center no-repeat;}
        .form-control.password {background: url("/assets/images/svg/lock.svg") left center no-repeat;}
        .form-control.user {background: url("/assets/images/svg/user.svg") left center no-repeat;}
        .form-control.call {background: url("/assets/images/svg/phone-call-2.svg") left center no-repeat;}
        .form-control.address {background: url("/assets/images/svg/map-pin-3.svg") left center no-repeat;}
        .form-control.zipcode {background: url("/assets/images/svg/zip-code.png") left center no-repeat;}
        .select {
            .form-control {padding-left: 0; background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;}
        }
        .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 40px;box-shadow: none;}
        .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
      .btn-link {padding: 0;border: 0;box-shadow: none;vertical-align: middle;color: $secondary;font-size: 15px;text-decoration: none;margin: 0px 0 0 20px; text-transform: uppercase;}
    }
  }

.booking-details {box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2); border-radius: 6px; padding: 20px;
    .coach.media {margin: 0 0px 25px 0;
        .image {margin-right: 25px;
            img {width: 113px; height: 113px; object-fit: cover; object-position: center; border-radius: 100%;}
        }
        h5 {margin: 0 0 7px 0; font-size: 20px; color: $secondary;
            a {color: $secondary; text-decoration: none;}
        }
        .rating {margin: 0 0 7px 0;
            ngb-rating {font-size: 24px; color: $primary; vertical-align: bottom; margin-right: 10px;}
            small {font-size: 12px; color: #AAA7A7;}
        }
        .address {font-size: 15px; color: $secondary;
            img {margin: 2px 15px 15px 0; float: left;}
        }
    }
    .titletea {font-size: 14px; color: $secondary; margin-bottom: 15px; font-weight: 500;}
    .customer.media {margin: 0 0px 0px 0;
        .image {margin-right: 30px;
            img {width: 80px; height: 80px; object-fit: cover; object-position: center; border-radius: 100%;}
        }
        h5 {margin: 0 0 7px 0; font-size: 20px; color: $secondary;}
        .rating {margin: 0 0 7px 0;
            ngb-rating {font-size: 24px; color: $primary; vertical-align: bottom; margin-right: 10px;}
            small {font-size: 12px; color: #AAA7A7;}
        }
        .address {font-size: 15px; color: $secondary;
            img {margin: 2px 15px 15px 0; float: left;}
        }
    }
    .detail {
        .date {font-size: 15px; color: $secondary; margin: 0 15px 15px 0; display: inline-block;}
        .text-success {font-size: 15px; font-weight: 700; margin: 0 0px 15px 0; display: inline-block;}
        .participants {
            .date {margin: 0 15px 0px 0;}
            .text-success {margin-right: 10px;}
        }
    }
    .sessionbtn { margin: 10px 0 0 0;
        .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 35px;box-shadow: none; margin: 0 10px 0 0;}
        .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
    }
    .btndiv { margin: 50px 0 0 0; text-align: right;
        .btn-warning {color: #FE9E2D;background-color: transparent;border: none;font-size: 15px; font-weight: 600; border-radius: 30px; padding: 0px;box-shadow: none; text-decoration: underline; margin-left: 25px;}
        .btn-warning:hover {background: transparent; border: none; color: #FE9E2D;}
        .btn-default {color: $secondary;background-color: transparent;border: none;font-size: 15px; font-weight: 600; border-radius: 30px; padding: 0px;box-shadow: none; text-decoration: underline; margin-left: 25px;}
        .btn-default:hover {background: transparent; border: none; color: $secondary;}
    }
    .order-status {text-align: left;}
}

.writetestimonial {
    h1 {font-size: 20px; font-weight: 700; color: $secondary; margin: 0 0 30px 0;}
    .testimonialform {
        .form-group {position: relative;margin-bottom: 40px;}
        .form-control {font-size: 15px;border: none;border-bottom: 1px solid #BBBBBB;height: 52px;border-radius: 0px;color: $secondary; box-shadow: none !important; background: none; padding: 0px 0 0 33px;}
        .form-control-placeholder {position: absolute;top: 12px;padding: 0px 0 0 33px;transition: all 200ms;font-size: 15px;font-weight: 500; color: $secondary;}
        .form-control:focus + .form-control-placeholder,
        .form-control:valid + .form-control-placeholder {font-size: 12px;transform: translate3d(0, -120%, 0);color: #8ABB2A; font-weight: 500;}
        .form-control.message {background: url("/assets/images/svg/info.svg") left center no-repeat;}
        .mat-radio-label-content {font-size: 12px; color: #AAA7A7; padding-left: 30px;}
        .mat-radio-outer-circle {border-color: $primary;}
        .mat-radio-button.mat-accent .mat-radio-inner-circle {background-color: $primary;}
        .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {border-color: $primary;}
        .rating {
            ngb-rating {font-size: 30px; color: $primary; vertical-align: text-top;
                span {margin-right: 5px;}
            }
        }
        .image {float: right; margin: -30px -55px 0 0px;}
        .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 60px;box-shadow: none;}
        .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
    }
}

.messagesec {box-shadow: 0 0 6px #d6d6d6; border: none; border-radius: 0px; background: $white; padding: 0px;
    .messages {
      .header {border-bottom: 1px solid #D8D5D5; padding: 10px; min-height: auto; box-shadow: none;
        img {width: 42px; height: 42px; object-fit: cover; object-position: center; border-radius: 100%; display: inline-block;}
        h5 {font-size: 15px; font-weight: 600; color: #393939; margin: 0 0 0 15px; display: inline-block; vertical-align: text-bottom; width: 115px;}
        .btn-primary {float: right; color: $white;background-color: $primary;border-color: $primary;line-height: 32px;font-size: 12px; font-weight: 600; border-radius: 30px; text-transform: uppercase;padding: 0 10px;box-shadow: none; margin-top: 5px;
            img {width: 7px; height: 14px; vertical-align: sub; margin-right: 5px;}
        }
        .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
      }
      .msg-list {padding: 10px 10px; height: 605px; overflow-y: auto;width: 100%;word-break: break-all; 
        ul {padding: 0px; margin: 0px; list-style: none;
          li { margin: 0 0 30px 0;
            .text {background: #F7ECFF; padding: 8px 12px; font-size: 14px; color: $secondary; border-radius: 20px; line-height: 24px;}
            .title {
              .name {font-size: 15px; color: $primary; font-style: normal; float: left;}
              .time {font-size: 12px; color: #AAA7A7; float: right; margin: 2px 0 0 0;}
            }
          }
          li.mine { margin: 0 0 30px 0;
            .text {background: #ffffff; padding: 8px 12px; font-size: 14px; color: $secondary; border-radius: 20px; border: 1px solid #D8D5D5; line-height: 24px;}
            .title {
              .name {font-size: 15px; color: #393939; font-style: normal; float: right; font-weight: 600;}
              .time {font-size: 12px; color: #AAA7A7; float: left; margin: 2px 0 0 0;}
            }
          }
        }
      }
      .mes-footer {border-top: 1px solid #D8D5D5; padding: 10px 10px;
        .form-control {border: none; box-shadow: none; font-size: 15px; color: $secondary; padding: 5px 0 0 0; resize: none; width: 218px; display: inline-block; vertical-align: top; height: 36px !important;}
        label {display: inline-block; margin-top: 0px !important; margin-bottom: 0px; margin-right: 15px !important;
          .MuiIconButton-root {padding: 6px;}
          .text-info {color: #7D7C7C !important;}
        }
        .fab-loading {display: inline-block;
          .MuiFab-sizeSmall {width: 36px; height: 36px; box-shadow: none; border: none; border-radius: 100%;}
        }
        .MuiIcon-root {font-size: 20px; color: #7D7C7C;}
      }
    }
  }

  .updatebackground {margin-bottom: 50px;
    .pastexperience {
        h3 {font-size: 18px; font-weight: 700; color: $secondary; margin: 0 0 30px 0;}
        .btn-outline-primary {line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; padding: 0 35px;box-shadow: none;}
        .mat-form-field {font-family: "Montserrat", sans-serif !important;}
        .mat-form-field-hide-placeholder .mat-form-field-label {color: $secondary;}
        .mat-focused .mat-form-field-label, .mat-form-field-should-float .mat-form-field-label {color: #8ABB2A;}
        .mat-form-field-appearance-legacy .mat-form-field-underline {background-color: #BBBBBB;}
        .mat-form-field-appearance-legacy .mat-form-field-infix {padding: 10px 0;}
        .form-group {margin-bottom: 25px;}
        .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {background-color: #BBBBBB; background-image: none;}
        .mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon, .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {width: 24px;}
        .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {border-color: $primary;}
        .mat-radio-outer-circle {border-color: $primary;}
        .mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {background-color: $primary;}
        .mat-radio-label {margin-right: 60px;}
        .mat-radio-label-content {font-family: "Montserrat", sans-serif !important;}
         mat-label {font-size: 15px; font-size: 500;}
         textarea.mat-input-element {height: 18px;}
        .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 50px;box-shadow: none;}
        .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
        .btn-secondary {color: $secondary;background-color: transparent;border: none;font-size: 15px; font-weight: 600; border-radius: 30px; padding: 0px;box-shadow: none; text-decoration: none; text-transform: uppercase; margin-left: 25px;}
        .btn-secondary:hover {background: transparent; border: none; color: $secondary;}
        .petop {border-bottom: 1px solid #D5D4D4; padding: 0 0 20px 0; margin-bottom: 30px;
            h4 {font-size: 15px; font-weight: 700; color: $secondary; margin: 0 0 8px 0;}
            .content {font-size: 15px; line-height: 26px; color: #6A6767; padding: 0 170px 0 0;}
            .btndiv {float: right; margin: -55px 0 0 0;
                .deletecard2 {width: 46px; height: 46px; font-size: 23px; color: $secondary; box-shadow: 0 0 6px #c0c0c0; border-radius: 4px; display: inline-block; background: $white; border: none; padding: 0px; margin-left: 16px;}
            }
        }
    }
  }
.updatebackground {
    .btn-circle {border-radius: 20px; font-size: 12px; font-weight: 700; text-transform: uppercase; padding: 10px 20px;}
    .invalid-feedback {display: block;}
    .mat-error {font-size: 12px;}
}

.availibility-page {padding: 0 0px 0 0;
    .mb-2 {padding-right: 30px;}
    h3 {font-weight: bold; font-size: 15px;}
    label.form-control-placeholder-2 {font-size: 12px; color: #8ABB2A; margin-bottom: 0px;}
    .form-group {position: relative;margin-bottom: 40px;}
    .select {position: relative;}
    .select::before {content: ""; width: 19px; height: 19px; background: url("/assets/images/svg/clock.svg") left center no-repeat; position: absolute; top: 15px;}
    .custom-select {font-size: 15px;border: none;border-bottom: 1px solid #BBBBBB;height: 50px;border-radius: 0px;color: $secondary; box-shadow: none !important; padding: 0px 0 0 26px; background-position: right center;}
    .form-control-placeholder {position: absolute;top: 12px;padding: 0px 0 0 26px;transition: all 200ms;font-size: 15px;font-weight: 500;z-index: -1;}
    .form-control:focus + .form-control-placeholder,
    .form-control:valid + .form-control-placeholder {font-size: 12px;transform: translate3d(0, -120%, 0);color: #8ABB2A; font-weight: 500;}
    .form-control.email {background: url("/assets/images/svg/email-2.svg") left center no-repeat;}
    .form-control.user {background: url("/assets/images/svg/user.svg") left center no-repeat;}
    .form-control.phone {background: url("/assets/images/svg/phone-call-2.svg") left center no-repeat;}
    .form-control.subject {background: url("/assets/images/svg/fitness.svg") left center no-repeat;}
    .form-control.message {background: url("/assets/images/svg/email.svg") left center no-repeat;}
    .custom-select.clock {background: url("/assets/images/svg/clock.svg") left center no-repeat;}
    .btn-link {padding: 0px; font-size: 19px; box-shadow: none;
        .fa-plus-circle {color: #FE9E2D;}
    }
    .btn-primary {color: $primary;background-color: $white;border-color: $primary;line-height: 40px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 20px;box-shadow: none;}
    .btn-primary:hover {background: $primary; border-color: $primary; color: $white;}
    .button {padding: 35px 0 0 0;}
    .notfound {font-size: 12px; color: #AAA7A7;}
}

.payment-page {text-align: center; padding-top: 100px;
    .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 50px;box-shadow: none;}
    .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
}

.coach-dashboard {
    .dashboard-top {margin: 0 -20px 50px -20px;
        .media {box-shadow: 0 0 6px #c0c0c0; padding: 15px 10px; border-radius: 8px; border-left: 4px solid #8ABB2A;
            .image {background: #8ABB2A; width: 66px; height: 66px; border-radius: 8px; margin: 0 15px 0 0; text-align: center; line-height: 62px;}
            .media-body {font-size: 14px; color: $secondary; font-weight: 700;
                h5 {font-size: 25px; font-weight: 700; color: #8ABB2A; margin: 0 0 10px 0;}
            }
        }
        .media.smallgroup {border-left: 4px solid #FE9E2D;
            .image {background: #FE9E2D;}
            .media-body {
                h5 {color: #FE9E2D;}
            }
        }
        .media.groupclass {border-left: 4px solid $primary;
            .image {background: $primary}
            .media-body {
                h5 {color: $primary;}
            }
        }
        .media.earning {border-left: 4px solid #0089C3;
            .image {background: #0089C3;}
            .media-body {
                h5 {color: #0089C3;}
            }
        }
    } 
    .dashboard-calender {margin: 0 0 50px 0;
        h2 {font-size: 20px; font-weight: 700; color: $secondary; margin: 0 0 30px 0;}
        .calender-sec {margin: 0 0 30px 0;
            th {font-weight: 600; font-size: 15px; padding: 10px 10px;}
            .fc .fc-daygrid-day-number {padding: 10px 10px; font-size: 15px; font-weight: 600;}
            .btn-toolbar {margin: 12px 0 0 0; position: absolute;
                .btn-info {color: $primary; border-radius: 30px !important; background: $white; border: 1px solid $white; line-height: 36px;font-size: 15px;text-transform: uppercase;padding: 0 30px;box-shadow: none; font-weight: 600; margin-left: 30px;}
            }
            .fc-header-toolbar {background: $primary; padding: 12px; margin-bottom: 0px;
                h2.fc-toolbar-title {font-size: 20px; color: $white; font-family: 'Recursive', sans-serif; font-weight: 400; margin: 0 0 0 100px;}
                .fc-button-group {
                    .fc-button-primary {border-radius: 30px !important; font-weight: 700; font-size: 15px; text-transform: uppercase; background: $primary; border-color: $primary;  padding: 0 15px; line-height: 36px; box-shadow: none;}
                    .fc-button-primary.fc-button-active {background: $white; border-color: $white; color: $primary;}
                }
            }
            
        }
        full-calendar.loading {opacity: 0.5; pointer-events: none;}
        .btn-toolbar {margin-bottom: -45px; position: relative; z-index: 9;}
        .fc .fc-toolbar {
            .fc-prev-button, .fc-next-button, .fc-today-button {opacity: 0; pointer-events: none;}
        } 
    }
    .dashboard-booking {margin: 0 0 50px 0;
        h2 {font-size: 20px; font-weight: 700; color: $secondary; margin: 0 0 30px 0;}
        .viewlink {text-align: right;
            a{font-size: 15px; color: $primary; text-decoration: underline; font-weight: 500;}
            a:hover{color: $secondary;}
        }
    }
    .dashboard-transactions {margin: 0 0 0px 0;
        h2 {font-size: 20px; font-weight: 700; color: $secondary; margin: 0 0 10px 0;}
        .viewlink {text-align: right;
            a{font-size: 15px; color: $primary; text-decoration: underline; font-weight: 500;}
            a:hover{color: $secondary;}
        }
    }
}

.change-password {
    .form-group {position: relative;margin-bottom: 40px;}
    .form-control {font-size: 15px;border: none;border-bottom: 1px solid #BBBBBB;height: 44px;border-radius: 0px;color: $secondary; box-shadow: none !important; background: none; padding: 0px 0 0 26px;}
    .form-control-placeholder {position: absolute;top: 12px;padding: 0px 0 0 26px;transition: all 200ms;font-size: 15px;font-weight: 500;z-index: 1;}
    .form-control:focus + .form-control-placeholder,
    .form-control:valid + .form-control-placeholder {font-size: 12px;transform: translate3d(0, -120%, 0);color: #8ABB2A; font-weight: 500;}
    .form-control.email {background: url("/assets/images/svg/email-2.svg") left center no-repeat;}
    .form-control.password {background: url("/assets/images/svg/lock.svg") left center no-repeat;}
    .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 40px;box-shadow: none;}
    .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
}

.public-group {
    .creatbtn { margin: -20px 0 30px 0;
        .btn-outline-warning {color: $warning;background-color: $white;border-color: $warning;line-height: 50px;font-size: 14px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 28px;box-shadow: none;}
        .btn-outline-warning:hover {background: $warning; border-color: $warning; color: $secondary;}
    }
}

.create-public-booking {
    h2 {font-size: 20px; font-weight: 700; color: $secondary; margin: 0 0 35px 0; text-transform: uppercase;}
}

.public-booking-listing {padding: 55px 0 25px 0;
    h1 {font-size: 25px; font-weight: 700; color: $secondary; margin: 0 0 30px 0;}
}

.public-booking-view {padding: 55px 0 25px 0;
    h1 {font-size: 25px; font-weight: 700; color: $secondary; margin: 0 0 30px 0;}
    .btndiv {text-align: right; margin: -10px 0 0 0; width: 100%;
        .btn-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 35px;box-shadow: none; margin: 0 0 0 10px;}
        .btn-primary:hover {background: $secondary; border-color: $secondary; color: $white;}
        .btn-outline-warning {line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 35px;box-shadow: none; margin: 0 0 0 10px;}
        .badge-primary {color: $white;background-color: $primary;border-color: $primary;line-height: 50px;font-size: 15px; font-weight: 700; border-radius: 30px; text-transform: uppercase;padding: 0 35px;box-shadow: none; margin: 0 0 0 10px;}
    }
}


@media (min-width: 1441px) and (max-width: 1843px) {
    .dashboard-card .col-sm-9.cdetail, .dashboard-card .col-sm-3.cdetail {flex: 0 0 100%; max-width: 100%;}
    .messagesec {margin: 30px 0 0 0;}
    .messagesec .messages .mes-footer .form-control {width: 91%;}
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .header .logo {padding: 15px 15px 0 15px; width: 100%;}
    .AfterLoginpage .userdatail .circle {width: 105px !important;}
    .home-banner .slide.two .caption {top: 75px;}
    .home-banner .slide.three .caption {top: 75px; left: 200px;}
    .home-banner .slide.four .caption {top: 10px;left: 200px;}
    .home-banner .slide.four .caption h1 {margin: 0 0 30px 0; line-height: 60px !important;}
    .home-bottom-banner .slide.two .caption {top: 75px;}
    .home-bottom-banner .slide.three .caption {top: 75px; left: 200px;}
    .home-bottom-banner .slide.four .caption {top: 10px;left: 200px;}
    .home-bottom-banner .slide.four .caption h1 {margin: 0 0 30px 0; line-height: 60px !important;}
    .home-disciplines {min-height: 480px; padding: 0px 0 0px 0; background-size: 100%;}
    .home-whychoose h1 {margin: 0 0 120px 0;}
    .home-areyoucoach {min-height: 604px; background-size: 100%;}
    .aboutus-page .dontwish {min-height: 680px;}
    .aboutus-page .whatweoffer .content {padding: 0 155px 0 0;}
    .faq-page .faq-search {background-size: 100%; min-height: 313px; padding: 65px 0 0 0;}
    .login-page.register-page .login-box {padding: 0px 30px;}
    .dashboard-card .dash-card-body {padding: 30px;}
    .coach-dashboard .dashboard-top {margin: 0 0px 20px 0px;}
    .coach-dashboard .dashboard-top .col-sm-3 {flex: 0 0 50%; max-width: 50%;}
    .coach-dashboard .dashboard-top .media {margin-bottom: 30px;}
    .transactions-page .table td {white-space: nowrap;}
    .dashboard-card .dash-card-title ul li {padding: 0px 0px 0 40px;}
    .dashboard-card .dash-card-title ul a {font-size: 17px;}
    .booking-box .profile-group {width: 100%;}
    .booking-box .time-group {width: 50%; padding-top: 15px;}
    .booking-box .cost {width: 50%; padding-top: 15px;}
    .booking-box .user-details {padding-top: 20px;}
    .dashboard-card .col-sm-9.cdetail, .dashboard-card .col-sm-3.cdetail {flex: 0 0 100%; max-width: 100%;}
    .booking-details .sessionbtn .btn-primary {padding: 0 25px; margin: 0 10px 10px 0;}
    .booking-details .detail .date {margin: 0 15px 0px 0;}
    .booking-details .coach.media .address img {margin: 2px 15px 30px 0;}
    .booking-details .customer.media .address img {margin: 2px 15px 30px 0;}
    .dashboard-card .col-sm-9.cdetail .dash-card-body {min-height: auto;}
    .messagesec {margin: 30px 0 0 0;}
    .messagesec .messages .mes-footer .form-control {width: 91%;}
    .update-profile .col-sm-9 {flex: 0 0 83.3333333333%; max-width: 83.3333333333%;}
    .dashboard-card .pagesubmenu ul {margin: 0px 0px 0px 0px;}
    .dashboard-card .pagesubmenu ul li {padding: 0 13px 0 0;}
    .dashboard-card .pagesubmenu ul li:last-child {padding: 0 0px 0 0;}
    .dashboard-card .pagesubmenu ul a {font-size: 14px;}
    .update-profile .col-sm-4 {flex: 0 0 41.6666666667%; max-width: 41.6666666667%;}
    .change-password .col-sm-5 {flex: 0 0 50%; max-width: 50%;}
    .updatebackground .col-sm-2.mobile {flex: 0 0 30%; max-width: 30%;}
    .update-profile .crprbtn .btn-primary {font-size: 14px;}
    .booking-box.public {padding-right: 60px;}
    .public-group .creatbtn {margin: 0px 0 30px 0;}
    .public-booking-view .booking-box.public {padding-right: 20px;}
}

@media (min-width: 992px) and (max-width: 1199px) {
    .header .logo {padding: 15px 15px 0 15px; width: 100%;}
    .header .header-bottom .mainmenu .navbar-expand-lg .navbar-nav .nav-item .nav-link {font-size: 13px; padding: 16px 15px;}
    .header .header-bottom .login-menu {margin: 23px 10px 0 0;}
    .header .header-bottom .login-menu a {font-size: 13px;padding-right: 10px;padding-left: 10px;}
    .home-banner .slide.two .caption {top: 95px;left: 0; padding: 0 30px; width: 100%;}
    .home-banner .slide.two .caption h1 {margin: 0 0 30px 0; line-height: 50px !important; font-size: 35px;}
    .home-banner .slide.three .caption {top: 95px;left: 0; padding: 0 30px; width: 100%;}
    .home-banner .slide.three .caption h1 {margin: 0 0 30px 0; line-height: 50px !important; font-size: 35px;}
    .home-banner .slide.four .caption {top: 20px;left: 0; padding: 0 30px; width: 100%;}
    .home-banner .slide.four .caption h1 {margin: 0 0 30px 0; line-height: 50px !important; font-size: 35px;}
    .home-bottom-banner .slide.two .caption {top: 95px;left: 0; padding: 0 30px; width: 100%;}
    .home-bottom-banner .slide.two .caption h1 {margin: 0 0 30px 0; line-height: 50px !important; font-size: 35px;}
    .home-bottom-banner .slide.three .caption {top: 95px;left: 0; padding: 0 30px; width: 100%;}
    .home-bottom-banner .slide.three .caption h1 {margin: 0 0 30px 0; line-height: 50px !important; font-size: 35px;}
    .home-bottom-banner .slide.four .caption {top: 20px;left: 0; padding: 0 30px; width: 100%;}
    .home-bottom-banner .slide.four .caption h1 {margin: 0 0 30px 0; line-height: 50px !important; font-size: 35px;}
    .home-howitwork .hiwbox.three .count {margin: 33px 0px 0px 20px;}
    .home-howitwork .hiwbox.one .content {font-size: 17px;}
    .home-howitwork .hiwbox.two .content {font-size: 17px;}
    .home-howitwork .hiwbox.three .content {font-size: 17px;}
    .home-howitwork .hiwbox.four .content {font-size: 17px;}
    .home-tryitout .container {max-width: 960px;}
    .home-tryitout .col-sm-4 {flex: 0 0 50%; max-width: 50%;}
    .home-tryitout .trybox {margin: 0 auto 30px auto;}
    .home-disciplines {min-height: 582px; padding: 0px 0 0px 0; background-size: 100%;}
    .home-whychoose h1 {margin: 0 0 100px 0;}
    .home-whychoose .content {margin: 0 0 100px 0;}
    .home-victorystories .content {padding: 0 0px;}
    .home-areyoucoach {min-height: 500px; background-size: 100%; padding: 80px 0 0px 0;}
    .footer .footer-address ul li img {float: left; margin: 7px 10px 15px 0;}
    .dashboard-card {margin-top: 0px;}
    .coach-listing .coach-box .image img {max-width: 117px; width: 117px; height: 117px;}
    .coach-listing .coach-box .detail .catename {margin: 0 22px 0 0 !important;}
    .coach-individual .ci-coverimage img {height: 300px;}
    .aboutus-page .dontwish {min-height: 600px;}
    .aboutus-page .bestronger .image img {max-width: 400px;}
    .aboutus-page .whychooseus .wcubox .title {font-size: 16px;}
    .aboutus-page .whychooseus .wcubox .content {font-size: 12px;}
    .aboutus-page .whatweoffer .content {padding: 0 0px 0 0;}
    .contactus-page .contact-detail .condiv .call {margin: 0 75px 0 0;}
    .contactus-page .contact-detail .condiv .email {margin: 0 75px 0 0;}
    .faq-page .faq-search {min-height: 259px; background-size: 100%; padding: 42px 0px 0px 0;}
    .faq-page .faq-search h1 {font-size: 30px;}
    .login-page.register-page .login-box {padding: 0px 30px;}
    .login-page.register-page .login-box .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
    .userdatail .profileimage {display: block !important; text-align: center !important; height: auto !important; margin-bottom: 30px !important;}
    .userdatail .circle {width: 94px !important; margin: 0 auto 15px auto !important;}
    .Left-Side .userdatail .profileimage label.btn {margin-left: 0px !important;}
    .dashboard-card .dash-card-title h1 {color: $secondary;}
    .dashboard-card {margin-top: 0px;}
    .dashboard-card .dash-card-body {padding: 30px;}
    .coach-dashboard .dashboard-top {margin: 0 0px 20px 0px;}
    .coach-dashboard .dashboard-top .col-sm-3 {flex: 0 0 33.3333%; max-width: 33.3333%;}
    .coach-dashboard .dashboard-top .media {margin-bottom: 30px;}
    .transactions-page .table td {white-space: nowrap;}
    .dashboard-card .dash-card-title ul li {padding: 0px 0px 0 40px;}
    .dashboard-card .dash-card-title ul a {font-size: 19px; color: $secondary;}
    .dashboard-card .dash-card-title ul a:hover, .dashboard-card .dash-card-title ul a.active {color: $primary;}
    .booking-box .profile-group {width: 100%;}
    .booking-box .time-group {width: 50%; padding-top: 15px;}
    .booking-box .cost {width: 50%; padding-top: 15px;}
    .booking-box .user-details {padding-top: 20px;}
    .dashboard-card .col-sm-9.cdetail, .dashboard-card .col-sm-3.cdetail {flex: 0 0 100%; max-width: 100%;}
    .booking-details .sessionbtn .btn-primary {padding: 0 25px; margin: 0 10px 10px 0;}
    .booking-details .detail .date {margin: 0 15px 0px 0;}
    .booking-details .coach.media .address img {margin: 2px 15px 30px 0;}
    .booking-details .customer.media .address img {margin: 2px 15px 30px 0;}
    .dashboard-card .col-sm-9.cdetail .dash-card-body {min-height: auto;}
    .messagesec {margin: 30px 0 0 0;}
    .messagesec .messages .mes-footer .form-control {width: 92%;}
    .addresses .setting-form .col-sm-5 {flex: 0 0 50%; max-width: 50%;}
    .addresses .setting-form .col-10 {flex: 0 0 100%; max-width: 100%;}
    .update-profile .col-sm-9 {flex: 0 0 100%; max-width: 100%;}
    .dashboard-card .pagesubmenu ul {margin: 0px 0px 0px 0px;}
    .dashboard-card .pagesubmenu ul li {padding: 0 23px 0 0;}
    .dashboard-card .pagesubmenu ul li:last-child {padding: 0 0px 0 0;}
    .dashboard-card .pagesubmenu ul a {font-size: 15px;}
    .update-profile .col-sm-4 {flex: 0 0 50%; max-width: 50%;}
    .change-password .col-sm-5 {flex: 0 0 50%; max-width: 50%;}
    .updatebackground .col-sm-2.mobile {flex: 0 0 30%; max-width: 20%;}
    .public-group .creatbtn {margin: 0px 0 30px 0;}
    .public-booking-view .booking-box.public .time-group .col-sm-2 {flex: 0 0 33.3333%; max-width: 33.3333%;}
    .public-booking-view .booking-box.public .time-group .col-sm-4 {flex: 0 0 100%; max-width: 100%;}
    .public-booking-view .booking-box .time-group .duration {margin-bottom: 15px;}
    .public-booking-view .btndiv {margin: 0;}
}

@media (min-width: 768px) and (max-width: 991px) {
    .header {padding: 0 0 15px 0;}
    .header .container {max-width: 720px; margin: 0 auto; padding: 0 15px;}
    .header .mainmenu .container {padding: 0 0px;}
    .header .logo {padding: 15px 0px 15px 0px; width: 100%; text-align: center;
        img {width: 150px;}
    }
    .header .navbar {padding: 0px;}
    .header .col-sm-2, .header .col-sm-10 {flex: 0 0 100%; max-width: 100%;}
    .header .header-top {display: none;}
    .header .header-bottom .login-menu {margin: 7px 0px 0 0;}
    .header .header-bottom .logged-menu {margin: 7px 0px 0 0;}
    .header .header-bottom .menulink {width: auto; height: auto; background: none; margin: 0 0 0 0; padding: 0 0 0 30px; line-height: inherit;}
    .header .header-bottom .menulink a {font-size: 15px; font-weight: 700; color: $white; text-transform: capitalize;background: $primary; line-height: 45px; border-radius: 30px; display: inline-block; padding: 0 20px;}
    .header .header-bottom .mainmenu .navbar-expand-lg .navbar-nav .nav-item .nav-link {padding: 7px 0;}
    .header .header-bottom .mainmenu .navbar-expand-lg .navbar-nav .nav-item:first-child .nav-link {padding-top: 15px;}
    .header .header-bottom .mainmenu .navbar-expand-lg .navbar-nav .nav-item {font-size: 0px;}
    .header .header-bottom .headersearch {transform: inherit; width: 50px; height: 45px; line-height: 42px; text-align: center; padding: 0 0; border-radius: 4px; position: absolute; right: 0px; top: -70px;}
    .header .header-bottom .headersearch .btn-search {transform: inherit;}
    .header .header-bottom .headersearch .btn-search img {transform: inherit;}
    .home-banner .slide.two .caption {top: 40px;left: 0; padding: 0 30px; width: 100%;}
    .home-banner .slide.two .caption h1 {margin: 0 0 30px 0; line-height: 50px !important; font-size: 35px;}
    .home-banner .slide.three .caption {top: 40px;left: 0; padding: 0 30px; width: 100%;}
    .home-banner .slide.three .caption h1 {margin: 0 0 30px 0; line-height: 50px !important; font-size: 35px;}
    .home-banner .slide.four .caption {top: 20px;left: 0; padding: 0 30px; width: 100%;}
    .home-banner .slide.four .caption h1 {margin: 0 0 30px 0; line-height: 42px !important; font-size: 25px;}
    .home-bottom-banner .slide.two .caption {top: 40px;left: 0; padding: 0 30px; width: 100%;}
    .home-bottom-banner .slide.two .caption h1 {margin: 0 0 30px 0; line-height: 50px !important; font-size: 35px;}
    .home-bottom-banner .slide.three .caption {top: 40px;left: 0; padding: 0 30px; width: 100%;}
    .home-bottom-banner .slide.three .caption h1 {margin: 0 0 30px 0; line-height: 50px !important; font-size: 35px;}
    .home-bottom-banner .slide.four .caption {top: 20px;left: 0; padding: 0 30px; width: 100%;}
    .home-bottom-banner .slide.four .caption h1 {margin: 0 0 30px 0; line-height: 42px !important; font-size: 25px;}
    .home-howitwork .hiwbox.three .count {margin: 13px 0px 0px 5px;}
    .home-howitwork .hiwbox.one .content {font-size: 17px;}
    .home-howitwork .hiwbox.two .content {font-size: 17px;}
    .home-howitwork .hiwbox.three .content {font-size: 17px;}
    .home-howitwork .hiwbox.four .content {font-size: 17px;}
    .home-tryitout .container {max-width: 720px;}
    .home-tryitout .col-sm-4 {flex: 0 0 50%; max-width: 50%;}
    .home-tryitout .trybox {margin: 0 auto 30px auto; width: 329px; background-size: 100% 100%;}
    .home-disciplines {min-height: 375px; padding: 0px 0 0px 0; background-size: 100%;}
    .home-whychoose h1 {margin: 0 0 50px 0; line-height: 55px !important; font-size: 30px;}
    .home-whychoose .content {margin: 0 0 50px 0;}
    .home-victorystories .content {padding: 0 0px;}
    .home-victorystories .victoryslider .vtsbox .image {width: 160px; height: 250px;}
    .home-victorystories .victoryslider .vtsbox .detail h3 {font-size: 20px; line-height: 30px !important; margin: 0 0 20px 0;}
    .home-victorystories .victoryslider .vtsbox .detail .comment {min-height: 150px;}
    .home-areyoucoach {min-height: 388px; background-size: 100%; padding: 20px 0 0px 0;}
    .footer .col-sm-2 {display: none;}
    .footer .image img {width: 150px;}
    .footer .col-sm-4 {flex: 0 0 50%; max-width: 50%;}
    .footer .footer-address ul li img {float: left; margin: 7px 10px 15px 0;}
    .coach-listing .coach-box .image img {max-width: 80px; width: 80px; height: 80px;}
    .coach-listing .coach-box .detail .catename {margin: 0 22px 0 0 !important;}
    .coach-listing .coach-box .rating {margin: 0 0 0 -1px;}
    .coach-listing .coach-box .rating ngb-rating {font-size: 20px;}
    .coach-listing .coach-box .address img {margin: 4px 10px 20px 0; float: left;}
    .coach-listing .refinesearch .searchkeword .form-control {font-size: 12px;}
    .coach-listing .refinesearch .classes .custom-control-label {font-size: 12px;}
    .coach-listing .refinesearch .language .select select {font-size: 12px;}
    .coach-listing .refinesearch .language .ngx-slider .ngx-slider-bubble {font-size: 12px;}
    .coach-listing .refinesearch .language .rating ngb-rating {font-size: 30px;}
    .coach-individual .ci-coverimage img {height: 230px;}
    .coach-individual .ci-right .ci-rating-box h2 .rating {left: 260px;}
    .coach-individual .ci-right .ci-rating-box h2 {font-size: 22px;}
    .coach-individual .ci-right .ci-expertise-box .expertisebox .expbox .image img {height: 150px;}
    .coach-individual .ci-right .ci-expertise-box .expertisebox .expbox .content {font-size: 13px; line-height: 20px; min-height: 60px;}
    .coach-individual .ci-right .ci-expertise-box h2 {font-size: 22px;}
    .coach-individual .ci-left {padding: 15px;}
    .coach-individual .ci-left .address {font-size: 12px;}
    .coach-individual .ci-left .aboutme .content {font-size: 12px;}
    .coach-individual .ci-left .prices {font-size: 12px;}
    .coach-individual .ci-right .ci-certification-box h2 {font-size: 22px;}
    .aboutus-page .dontwish {min-height: 465px;}
    .aboutus-page .dontwish h1 {font-size: 35px; margin: 25px 0 20px 0;}
    .aboutus-page .dontwish .content {font-size: 19px;}
    .aboutus-page .bestronger .image img {max-width: 308px;}
    .whychooseus .col-sm-3 {flex: 0 0 50%; max-width: 50%;}
    .aboutus-page .whychooseus .wcubox .title {font-size: 18px;}
    .aboutus-page .whychooseus .wcubox.one {margin-bottom: 90px;}
    .aboutus-page .whychooseus .wcubox.two {margin-bottom: 90px;}
    .aboutus-page .whatweoffer .content {padding: 0 0px 0 0;}
    .contactus-page .contact-detail {margin: -100px 0 50px 0;}
    .contactus-page .contact-detail h1 {font-size: 31px; line-height: 50px !important;}
    .contactus-page .contact-detail .condiv .call {margin: 0 75px 20px 0;}
    .contactus-page .contact-detail .condiv .email {margin: 0 0px 0 0;}
    .contactus-page .contact-form {padding: 315px 0 50px 0;}
    .faq-page .faq-search {min-height: 200px; background-size: 100%; padding: 15px 0px 0px 0;}
    .faq-page .faq-search h1 {font-size: 23px;}
    .faq-page .faq-bottom .faq-left .fl-header {font-size: 20px;}
    .faq-page .faq-bottom .faq-left .fl-footer h5 {font-size: 14px;}
    .login-page .container-fluid {max-width: 720px; padding: 0 15px;}
    .login-page .row.main {margin: 0 -15px;} 
    .login-page .col-sm-6.main {padding: 0 15px; flex: 0 0 100%; max-width: 100%;}
    .login-page .image img {display: none;}
    .login-page .logoimg {position: relative; margin: 15px auto 0 auto; height: auto; width: 230px;}
    .login-page .login-box h1 {margin: 50px 0 50px 0;}
    .login-page .login-box .btn-primary {margin-bottom: 30px;}
    .login-page.forgetpassword .login-box h1 {margin: 50px 0 50px 0;}
    .userdatail .profileimage {display: block !important; text-align: center !important; height: auto !important; margin-bottom: 30px !important;}
    .userdatail .circle {width: 94px !important; margin: 0 auto 15px auto !important;}
    .Left-Side .userdatail .profileimage label.btn {margin-left: 0px !important;}
    .dashboard-card .dash-card-title h1 {color: $secondary;}
    .dashboard-card {margin-top: 0px;}
    .dashboard-card .dash-card-body {padding: 30px;}
    .coach-dashboard .dashboard-top {margin: 0 0px 20px 0px;}
    .coach-dashboard .dashboard-top .col-sm-3 {flex: 0 0 50%; max-width: 50%;}
    .coach-dashboard .dashboard-top .media {margin-bottom: 30px;}
    .transactions-page .table td {white-space: nowrap;}
    .coach-dashboard .dashboard-calender .calender-sec .btn-toolbar .btn-info {padding: 0 10px;line-height: 25px;font-size: 13px;height: 27px;}
    .coach-dashboard .dashboard-calender .calender-sec .btn-toolbar .btn-primary {height: 27px; padding: 0 5px;}
    .coach-dashboard .dashboard-calender .calender-sec .btn-toolbar .btn-info {margin-left: 0px;}
    .coach-dashboard .dashboard-calender .calender-sec .fc-header-toolbar h2.fc-toolbar-title {font-size: 18px; margin: 0 0 0 30px;}
    .coach-dashboard .dashboard-calender .calender-sec .fc-header-toolbar .fc-button-group .fc-button-primary {padding: 0 10px;line-height: 25px;font-size: 13px;}
    .dashboard-card .dash-card-title ul {margin: 0px 0px 30px 0px;}
    .dashboard-card .dash-card-title ul li {padding: 0px 24px 0 0px;}
    .dashboard-card .dash-card-title ul a {font-size: 14px; color: $secondary;}
    .dashboard-card .dash-card-title ul a:hover, .dashboard-card .dash-card-title ul a.active {color: $primary;}
    .booking-box .profile-group {width: 100%;}
    .booking-box .time-group {width: 65%; padding-top: 15px;}
    .booking-box .cost {width: 35%; padding-top: 15px;}
    .booking-box .user-details {padding-top: 10px;}
    .booking-box .user-details .location {font-size: 15px; padding: 0 0px 0 0;}
    .dashboard-card .col-sm-9.cdetail, .dashboard-card .col-sm-3.cdetail {flex: 0 0 100%; max-width: 100%;}
    .booking-details .sessionbtn .btn-primary {padding: 0 25px; margin: 0 10px 10px 0;}
    .booking-details .detail .date {margin: 0 15px 0px 0;}
    .booking-details .coach.media .address img {margin: 2px 15px 30px 0;}
    .booking-details .customer.media .address img {margin: 2px 15px 30px 0;}
    .dashboard-card .col-sm-9.cdetail .dash-card-body {min-height: auto;}
    .messagesec {margin: 30px 0 0 0;}
    .messagesec .messages .mes-footer .form-control {width: 89%;}
    .booking-details .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
    .booking-details .titletea {margin-top: 20px;}
    .booking-details .detail .col-sm-6 {flex: 0 0 50%; max-width: 50%;}
    .notification-page .table td {white-space: nowrap;}
    .addresses .setting-form .col-sm-5 {flex: 0 0 50%; max-width: 50%;}
    .addresses .setting-form .col-10 {flex: 0 0 100%; max-width: 100%;}
    .update-profile .col-sm-9 {flex: 0 0 100%; max-width: 100%;}
    .update-profile .col-sm-6.col-12 {flex: 0 0 100%; max-width: 100%;}
    .dashboard-card .pagesubmenu {margin-bottom: 30px;}
    .dashboard-card .pagesubmenu ul {margin: 0px 0px 0px 0px; display: block;}
    .dashboard-card .pagesubmenu ul li {padding: 0 0px 20px 0; float: left; width: 50%;}
    .dashboard-card .pagesubmenu ul li:last-child {padding: 0 0px 0 0;}
    .dashboard-card .pagesubmenu ul a {font-size: 16px; padding: 0 0 0 0;}
    .update-profile .col-sm-4 {flex: 0 0 50%; max-width: 50%;}
    .availibility-page .col-sm-6.col-12 {flex: 0 0 100%; max-width: 100%;}
    .change-password .col-sm-5 {flex: 0 0 50%; max-width: 50%;}
    .dashboard-card .dash-card-body {min-height: auto;}
    .updatebackground .col-sm-2.mobile {flex: 0 0 30%; max-width: 30%;}
    .update-profile .col-sm-6.main {flex: 0 0 100%; max-width: 100%;}
    .update-profile .crprbtn {padding: 0px 0 50px 0; text-align: right;}
    .update-profile .profileimage {margin-bottom: 20px !important;}
    .public-group .creatbtn {margin: 0px 0 30px 0;}
    .booking-box.public .time-group.public .col-sm-3 {flex: 0 0 50%; max-width: 50%;}
    .create-public-booking.update-profile .col-sm-3 {flex: 0 0 50%; max-width: 50%;}
    .public-booking-view .booking-box.public .time-group .col-sm-2 {flex: 0 0 33.3333%; max-width: 33.3333%;}
    .public-booking-view .booking-box.public .time-group .col-sm-4 {flex: 0 0 100%; max-width: 100%;}
    .public-booking-view .booking-box .time-group .duration {margin-bottom: 15px;}
    .public-booking-view .btndiv {margin: 0;}
}

@media (min-width: 576px) and (max-width: 767px) {
    .header {padding: 0 0 15px 0;}
    .header .container {max-width: 540px; margin: 0 auto; padding: 0 15px;}
    .header .mainmenu .container {padding: 0 0px;}
    .header .logo {padding: 15px 0px 15px 0px; width: 100%; text-align: center;
        img {width: 150px;}
    }
    .header .navbar {padding: 0px;}
    .header .col-sm-2, .header .col-sm-10 {flex: 0 0 100%; max-width: 100%;}
    .header .header-top {display: none;}
    .header .header-bottom .login-menu {margin: 7px 0px 0 0;}
    .header .header-bottom .logged-menu {margin: 7px 0px 0 0;}
    .header .header-bottom .menulink {width: auto; height: auto; background: none; margin: 0 0 0 0; padding: 0 0 0 30px; line-height: inherit;}
    .header .header-bottom .menulink a {font-size: 15px; font-weight: 700; color: $white; text-transform: capitalize;background: $primary; line-height: 45px; border-radius: 30px; display: inline-block; padding: 0 20px;}
    .header .header-bottom .mainmenu .navbar-expand-lg .navbar-nav .nav-item .nav-link {padding: 7px 0;}
    .header .header-bottom .mainmenu .navbar-expand-lg .navbar-nav .nav-item:first-child .nav-link {padding-top: 15px;}
    .header .header-bottom .mainmenu .navbar-expand-lg .navbar-nav .nav-item {font-size: 0px;}
    .header .header-bottom .mainmenu .navbar-collapse {width: 45px;}
    .header .header-bottom .headersearch {transform: inherit; width: 50px; height: 45px; line-height: 42px; text-align: center; padding: 0 0; border-radius: 4px; position: absolute; right: 0px; top: -70px;}
    .header .header-bottom .headersearch .btn-search {transform: inherit;}
    .header .header-bottom .headersearch .btn-search img {transform: inherit;}
    .home-banner .slide.one .caption {left: 30px; width: 272px;}
    .home-banner .slide.one .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 18px;}
    .home-banner .slide.one .caption .btn-primary {line-height: 40px; padding: 0 30px;}
    .home-banner .slide.two .caption {top: 30px;left: 0; padding: 0 30px; width: 100%;}
    .home-banner .slide.two .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 18px;}
    .home-banner .slide.two .caption .btn-primary {line-height: 40px; padding: 0 30px;}
    .home-banner .slide.three .caption {top: 30px;left: 0; padding: 0 30px; width: 100%;}
    .home-banner .slide.three .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 18px;}
    .home-banner .slide.three .caption .btn-primary {line-height: 40px; padding: 0 30px;}
    .home-banner .slide.four .caption {top: 30px;left: 0; padding: 0 30px; width: 100%;}
    .home-banner .slide.four .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 18px;}
    .home-banner .slide.four .caption .btn-primary {line-height: 40px; padding: 0 30px;}
    .home-bottom-banner .slide.one .caption {left: 30px; width: 272px;}
    .home-bottom-banner .slide.one .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 18px;}
    .home-bottom-banner .slide.one .caption .btn-primary {line-height: 40px; padding: 0 30px;}
    .home-bottom-banner .slide.two .caption {top: 30px;left: 0; padding: 0 30px; width: 100%;}
    .home-bottom-banner .slide.two .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 18px;}
    .home-bottom-banner .slide.two .caption .btn-primary {line-height: 40px; padding: 0 30px;}
    .home-bottom-banner .slide.three .caption {top: 30px;left: 0; padding: 0 30px; width: 100%;}
    .home-bottom-banner .slide.three .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 18px;}
    .home-bottom-banner .slide.three .caption .btn-primary {line-height: 40px; padding: 0 30px;}
    .home-bottom-banner .slide.four .caption {top: 30px;left: 0; padding: 0 30px; width: 100%;}
    .home-bottom-banner .slide.four .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 18px;}
    .home-bottom-banner .slide.four .caption .btn-primary {line-height: 40px; padding: 0 30px;}
    .home-howitwork .container {max-width: 540px;}
    .home-howitwork .col-sm-4, .home-howitwork .col-sm-2, .home-howitwork .col-sm-3 {flex: 0 0 50%; max-width: 50%;}
    .home-howitwork .hiwbox.one .content {padding: 0 0px;}
    .home-howitwork .hiwbox.one {margin: 0px 0 0 0;}
    .home-howitwork .hiwbox.two {margin: 0px 0 50px 0;}
    .home-howitwork .hiwbox.three {margin: 0px 0 0 0;}
    .home-howitwork .hiwbox.three .count {margin: 35px 0px 0px 25px;}
    .home-tryitout .container {max-width: 540px;}
    .home-tryitout .col-sm-4 {flex: 0 0 100%; max-width: 100%;}
    .home-tryitout .trybox {margin: 0 auto 30px auto; background-size: 100% 100%;}
    .home-disciplines h1 {font-size: 28px;}
    .home-disciplines {min-height: 280px; padding: 0px 0 0px 0; background-size: 100%;}
    .home-whychoose h1 {margin: 0 0 45px 0; line-height: 35px !important; font-size: 22px;}
    .home-whychoose .content {margin: 0 0 45px 0; font-size: 15px; line-height: 25px;}
    .home-victorystories .content {padding: 0 0px; line-height: 35px; font-size: 18px;}
    .home-victorystories .victoryslider {padding-bottom: 15px;}
    .home-victorystories .victoryslider .vtsbox .image {width: 100px; height: 250px; margin: 0 15px 0 0;}
    .home-victorystories .victoryslider .vtsbox .detail h3 {font-size: 18px; line-height: 30px !important; margin: 0 0 20px 0;}
    .home-victorystories .victoryslider .vtsbox .detail .comment {min-height: 150px; font-size: 12px; line-height: 22px;}
    .home-victorystories .victoryslider .owl-carousel .owl-nav .owl-next {right: -88px;}
    .home-victorystories .victoryslider .owl-carousel .owl-nav .owl-prev {left: -88px;}
    .home-victorystories .victoryslider .owl-carousel .owl-nav .owl-prev, .home-victorystories .victoryslider .owl-carousel .owl-nav .owl-next {top: 40%;}
    .home-areyoucoach {min-height: 290px; background-size: 100%; padding: 20px 0 0px 0;}
    .home-areyoucoach h1 {font-size: 40px; line-height: 50px !important;}
    .footer .col-sm-2 {display: none;}
    .footer .image img {width: 150px;}
    .footer .col-sm-4 {flex: 0 0 50%; max-width: 50%;}
    .footer .footer-address ul li img {float: left; margin: 7px 10px 15px 0;}
    .coach-listing .col-sm-4, .coach-listing .col-sm-8 {flex: 0 0 100%; max-width: 100%;}
    .coach-listing .refinesearch {margin: 0px 0 30px 0;}
    .coach-listing .coach-box .image img {max-width: 95px; width: 95px; height: 95px;}
    .coach-listing .coach-box .detail .catename {margin: 0 22px 0 0 !important;}
    .coach-listing .coach-box .rating {margin: 0 0 0 -1px;}
    .coach-listing .coach-box .rating ngb-rating {font-size: 23px;}
    .coach-listing .coach-box .address img {margin: 4px 10px 20px 0; float: left;}
    .coach-individual .ci-coverimage img {height: 160px;}
    .coach-individual .col-sm-4.main, .coach-individual .col-sm-8.main {flex: 0 0 100%; max-width: 100%;}
    .coach-individual .ci-right .ci-expertise-box .expertisebox .expbox .image img {height: 160px;}
    .coach-individual .ci-right .ci-expertise-box .expertisebox .expbox .content {min-height: 78px;}
    .aboutus-page .dontwish {min-height: 385px;}
    .aboutus-page .dontwish h1 {font-size: 40px; margin: 20px 0 20px 0;}
    .aboutus-page .dontwish .col-sm-7 {flex: 0 0 100%; max-width: 100%;}
    .aboutus-page .dontwish .content {font-size: 18px; padding: 0 0px 0 0;}
    .aboutus-page .bestronger .col-sm-7, .aboutus-page .bestronger .col-sm-5 {flex: 0 0 100%; max-width: 100%;}
    .aboutus-page .bestronger .image img {max-width: 504px;}
    .aboutus-page .whychooseus h1 {font-size: 33px;}
    .whychooseus .col-sm-3 {flex: 0 0 50%; max-width: 50%;}
    .aboutus-page .whychooseus .wcubox.one {margin-bottom: 90px;}
    .aboutus-page .whychooseus .wcubox.two {margin-bottom: 90px;}
    .whatweoffer .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
    .aboutus-page .whatweoffer {padding: 90px 0px 0px 0;}
    .aboutus-page .whatweoffer .content {margin: 0 0 30px 0; padding: 0 0px 0 0;}
    .aboutus-page .whatweoffer .servicebox {max-width: 100%;}
    .contactus-page .contact-detail {margin: -80px 0 50px 0;}
    .contactus-page .contact-detail h1 {font-size: 22px; line-height: 40px !important;}
    .contactus-page .contact-detail .condiv .call {margin: 0 75px 20px 0;}
    .contactus-page .contact-detail .condiv .email {margin: 0 0px 0 0;}
    .contactus-page .contact-detail .content {font-size: 16px;}
    .contactus-page .contact-form {padding: 315px 0 50px 0;}
    .contactus-page .contact-form .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
    .faq-page .faq-search .col-sm-2, .faq-page .faq-search .col-sm-8 {flex: 0 0 100%; max-width: 100%;}
    .faq-page .faq-search {min-height: 150px; background-size: 100%; padding: 20px 0px 0px 0;}
    .faq-page .faq-search h1 {font-size: 23px; line-height: 28px !important;}
    .faq-page .faq-search .form-control {height: 45px;}
    .faq-page .faq-search .btn-outline-secondary {line-height: 43px;}
    .faq-page .faq-bottom {padding: 50px 0 0 0;}
    .faq-page .faq-bottom .col-sm-4, .faq-page .faq-bottom .col-sm-8 {flex: 0 0 100%; max-width: 100%;}
    .faq-page .faq-bottom .faq-left {margin: 0 0 30px 0;}
    .faq-page .faq-bottom .faq-right .accordion .card .card-header {padding: 15px 15px;}
    .faq-page .faq-bottom .faq-right .accordion .card .card-body {padding: 15px 15px;}
    .faq-page .faq-bottom .faq-left .fl-body ul li a {padding: 15px;}
    .login-page .container-fluid {max-width: 540px; padding: 0 15px;}
    .login-page .row.main {margin: 0 -15px;} 
    .login-page .col-sm-6.main {padding: 0 15px; flex: 0 0 100%; max-width: 100%;}
    .login-page .image img {display: none;}
    .login-page .logoimg {position: relative; margin: 15px auto 0 auto; height: auto; width: 230px;}
    .login-page .login-box h1 {margin: 50px 0 50px 0;}
    .login-page .login-box .btn-primary {margin-bottom: 30px;}
    .login-page.register-page .login-box .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
    .login-page.forgetpassword .login-box h1 {margin: 50px 0 50px 0;}
    .userdatail .profileimage {display: block !important; text-align: center !important; height: auto !important; margin-bottom: 30px !important;}
    .userdatail .circle {width: 94px !important; margin: 0 auto 15px auto !important;}
    .Left-Side .userdatail .profileimage label.btn {margin-left: 0px !important;}
    .dashboard-card .dash-card-title h1 {color: $secondary;}
    .dashboard-card {margin-top: 0px;}
    .dashboard-card .dash-card-body {padding: 30px;}
    .coach-dashboard .dashboard-top {margin: 0 0px 20px 0px;}
    .coach-dashboard .dashboard-top .col-sm-3 {flex: 0 0 50%; max-width: 50%;}
    .coach-dashboard .dashboard-top .media {margin-bottom: 30px; min-height: 122px;}
    .transactions-page .table td {white-space: nowrap;}
    .coach-dashboard .dashboard-calender .calender-sec .btn-toolbar .btn-info {padding: 0 10px;line-height: 25px;font-size: 13px;height: 27px;}
    .coach-dashboard .dashboard-calender .calender-sec .btn-toolbar .btn-primary {height: 27px; padding: 0 5px;}
    .coach-dashboard .dashboard-calender .calender-sec .btn-toolbar .btn-info {margin-left: 0px;}
    .coach-dashboard .dashboard-calender .calender-sec .fc-header-toolbar h2.fc-toolbar-title {font-size: 18px; margin: 0 0 0 30px;}
    .coach-dashboard .dashboard-calender .calender-sec .fc-header-toolbar .fc-button-group .fc-button-primary {padding: 0 10px;line-height: 25px;font-size: 13px;}
    .coach-dashboard .dashboard-calender .calender-sec .fc .fc-button-group {display: none;}
    .dashboard-card .dash-card-title ul {margin: 0px 0px 15px 0px; display: block;}
    .dashboard-card .dash-card-title ul li {padding: 0px 0px 15px 0px;}
    .dashboard-card .dash-card-title ul a {color: $secondary;}
    .dashboard-card .dash-card-title ul a:hover, .dashboard-card .dash-card-title ul a.active {color: $primary;}
    .booking-box .profile-group {width: 100%;}
    .booking-box .time-group {width: 100%; padding-top: 15px;}
    .booking-box .cost {width: 100%; padding-top: 0px;}
    .booking-box .user-details {padding-top: 10px;}
    .booking-box .user-details .location {font-size: 15px; padding: 0 0px 0 0;}
    .dashboard-card .col-sm-9.cdetail, .dashboard-card .col-sm-3.cdetail {flex: 0 0 100%; max-width: 100%;}
    .booking-details .sessionbtn .btn-primary {padding: 0 25px; margin: 0 10px 10px 0;}
    .booking-details .detail .date {margin: 0 15px 0px 0;}
    .booking-details .coach.media .address img {margin: 2px 15px 30px 0;}
    .booking-details .customer.media .address img {margin: 2px 15px 30px 0;}
    .dashboard-card .col-sm-9.cdetail .dash-card-body {min-height: auto;}
    .messagesec {margin: 30px 0 0 0;}
    .messagesec .messages .mes-footer .form-control {width: 86%;}
    .booking-details .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
    .booking-details .titletea {margin-top: 20px;}
    .booking-details .detail .col-sm-6 {flex: 0 0 50%; max-width: 50%;}
    .custom-modal .modal-dialog {min-width: auto;}
    .notification-page .table td {white-space: nowrap;}
    .addresses .setting-form .col-sm-5 {flex: 0 0 50%; max-width: 50%;}
    .addresses .setting-form .col-10 {flex: 0 0 100%; max-width: 100%;}
    .update-profile .col-sm-9 {flex: 0 0 100%; max-width: 100%;}
    .update-profile .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
    .dashboard-card .pagesubmenu {margin-bottom: 30px;}
    .dashboard-card .pagesubmenu ul {margin: 0px 0px 0px 0px; display: block;}
    .dashboard-card .pagesubmenu ul li {padding: 0 0px 20px 0; float: left; width: 50%;}
    .dashboard-card .pagesubmenu ul li:last-child {padding: 0 0px 0 0;}
    .dashboard-card .pagesubmenu ul a {font-size: 13px; padding: 0 0 0 0;}
    .updatebackground .pastexperience .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
    .updatebackground .pastexperience .col-sm-4 {flex: 0 0 100%; max-width: 100%;}
    .update-profile .col-sm-4 {flex: 0 0 100%; max-width: 100%;}
    .availibility-page .col-sm-6.col-12 {flex: 0 0 100%; max-width: 100%;}
    .change-password .col-sm-5 {flex: 0 0 100%; max-width: 100%;}
    .dashboard-card .dash-card-body {min-height: auto;}
    .update-profile .crprbtn {padding: 0px 0 50px 0; text-align: right;}
    .update-profile .profileimage {margin-bottom: 20px !important;}
    .public-group .creatbtn {margin: 0px 0 30px 0;}
    .booking-box.public .time-group.public .col-sm-3 {flex: 0 0 50%; max-width: 50%;}
    .create-public-booking.update-profile .col-sm-3 {flex: 0 0 50%; max-width: 50%;}
    .public-booking-view .booking-box.public .time-group .col-sm-2 {flex: 0 0 33.3333%; max-width: 33.3333%;}
    .public-booking-view .booking-box.public .time-group .col-sm-4 {flex: 0 0 100%; max-width: 100%;}
    .public-booking-view .booking-box .time-group .duration {margin-bottom: 15px;}
    .public-booking-view .btndiv {margin: 0;}
}

@media (min-width: 320px) and (max-width: 575px) {
    .header {padding: 0 0 15px 0;}
    .header .container {max-width: 100%; margin: 0 auto; padding: 0 15px;}
    .header .mainmenu .container {padding: 0 0px;}
    .header .logo {padding: 15px 0px 15px 0px; width: 100%; text-align: center;
        img {width: 150px;}
    }
    .header .navbar {padding: 0px;}
    .header .col-sm-2, .header .col-sm-10 {flex: 0 0 100%; max-width: 100%;}
    .header .header-top {display: none;}
    .header .header-bottom .login-menu {margin: 7px 0px 0 0;}
    .header .header-bottom .logged-menu {margin: 7px 0px 0 0;}
    .header .header-bottom .menulink {width: auto; height: auto; background: none; margin: 0 0 0 0; padding: 15px 0 0 0px; line-height: inherit; display: block;}
    .header .header-bottom .menulink a {font-size: 15px; font-weight: 700; color: $white; text-transform: capitalize;background: $primary; line-height: 45px; border-radius: 30px; display: inline-block; padding: 0 20px;}
    .header .header-bottom .mainmenu .navbar-expand-lg .navbar-nav .nav-item .nav-link {padding: 7px 0;}
    .header .header-bottom .mainmenu .navbar-expand-lg .navbar-nav .nav-item:first-child .nav-link {padding-top: 15px;}
    .header .header-bottom .mainmenu .navbar-expand-lg .navbar-nav .nav-item {font-size: 0px;}
    .header .header-bottom .mainmenu .navbar-collapse {width: 45px;}
    .header .header-bottom .headersearch {transform: inherit; width: 35px; height: 35px; line-height: 20px; text-align: center; padding: 6px; border-radius: 4px; position: absolute; right: 0px; top: -64px;}
    .header .header-bottom .headersearch .btn-search {transform: inherit;}
    .header .header-bottom .headersearch .btn-search img {transform: inherit;}
    .home-banner .slide.one .caption {left: 30px; width: 100%; display: none;}
    .home-banner .slide.one .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 18px; display: none;}
    .home-banner .slide.one .caption .btn-primary {line-height: 40px; padding: 0 30px;}
    .home-banner .slide.two .caption {top: 30px;left: 0; padding: 0 30px; width: 100%; display: none;}
    .home-banner .slide.two .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 18px; display: none;}
    .home-banner .slide.two .caption .btn-primary {line-height: 40px; padding: 0 30px;}
    .home-banner .slide.three .caption {top: 30px;left: 0; padding: 0 30px; width: 100%; display: none;}
    .home-banner .slide.three .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 18px; display: none;}
    .home-banner .slide.three .caption .btn-primary {line-height: 40px; padding: 0 30px;}
    .home-banner .slide.four .caption {top: 30px;left: 0; padding: 0 30px; width: 100%; display: none;  }
    .home-banner .slide.four .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 18px; display: none;}
    .home-banner .slide.four .caption .btn-primary {line-height: 40px; padding: 0 30px;}
    .home-bottom-banner .slide.one .caption {left: 30px; width: 100%;}
    .home-bottom-banner .slide.one .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 18px;}
    .home-bottom-banner .slide.one .caption .btn-primary {line-height: 40px; padding: 0 30px;}
    .home-bottom-banner .slide.two .caption {top: 20px;left: 0; padding: 0 30px; width: 100%;}
    .home-bottom-banner .slide.two .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 14px;}
    .home-bottom-banner .slide.two .caption .btn-primary {line-height: 40px; padding: 0 30px; display: none;}
    .home-bottom-banner .slide.three .caption {top: 20px;left: 0; padding: 0 30px; width: 100%;}
    .home-bottom-banner .slide.three .caption h1 {margin: 0 0 20px 0; line-height: 28px !important; font-size: 14px;}
    .home-bottom-banner .slide.three .caption .btn-primary {line-height: 40px; padding: 0 30px; display: none;}
    .home-bottom-banner .slide.four .caption {top: 10px;left: 0; padding: 0 30px; width: 100%;}
    .home-bottom-banner .slide.four .caption h1 {margin: 0 0 20px 0; line-height: 18px !important; font-size: 14px;}
    .home-bottom-banner .slide.four .caption .btn-primary {line-height: 40px; padding: 0 30px; display: none;}
    .home-bottom-banner .owl-theme .owl-dots {margin: -28px 0 0 0;}
    .home-howitwork {padding: 40px 0 40px 0;}
    .home-howitwork h1 {font-size: 30px; margin: 0 0 50px 0;}
    .home-howitwork .container {max-width: 100%;}
    .home-howitwork .hiwbox.one .content {padding: 0 0px;}
    .home-howitwork .hiwbox.one {margin: 0px 0 50px 0;}
    .home-howitwork .hiwbox.one .image {margin: 0 0 20px 0;}
    .home-howitwork .hiwbox.two {margin: 0px 0 50px 0;}
    .home-howitwork .hiwbox.two .image {margin: 0 0 20px 0;}
    .home-howitwork .hiwbox.three {margin: 0px 0 50px 0;}
    .home-howitwork .hiwbox.three .count {margin: 35px 0px 0px 25px;}
    .home-howitwork .hiwbox.three .image {margin: 0 0 20px 0;}
    .home-howitwork .hiwbox.four .image {margin: 0 0 20px 0;}
    .home-tryitout {padding: 0px 0 20px 0;}
    .home-tryitout .container {max-width: 100%;}
    .home-tryitout .col-sm-4 {flex: 0 0 100%; max-width: 100%;}
    .home-tryitout h1 {font-size: 30px; margin: 0 0 20px 0;}
    .home-tryitout .content {font-size: 15px; margin: 0 0 30px 0;}
    .home-tryitout .trybox {margin: 0 auto 30px auto; width: 290px; background-size: 100% 100%;}
    .home-disciplines h1 {font-size: 17px;}
    .home-disciplines {min-height: 158px; padding: 0px 0 0px 0; background-size: 100%;}
    .home-whychoose {padding: 50px 0 50px 0;}
    .home-whychoose h1 {margin: 20px 0 20px 0; line-height: 35px !important; font-size: 22px; text-align: center;}
    .home-whychoose .content {margin: 0 0 20px 0; font-size: 15px; line-height: 25px; text-align: center;}
    .home-whychoose .btndiv {text-align: center;}
    .home-victorystories {padding: 50px 0 50px 0;}
    .home-victorystories h1 {font-size: 30px; margin: 0 0 20px 0;}
    .home-victorystories .content {padding: 0 0px; line-height: 26px; font-size: 15px; margin: 0 0 30px 0;}
    .home-victorystories .victoryslider {padding-bottom: 15px;}
    .home-victorystories .victoryslider .vtsbox .image {width: 150px;height: 160px;margin: 0 auto; float: none;}
    .home-victorystories .victoryslider .vtsbox .detail h3 {font-size: 18px; line-height: 30px !important; margin: 15px 0 20px 0; text-align: center;}
    .home-victorystories .victoryslider .vtsbox .detail .comment {min-height: auto; font-size: 12px; line-height: 22px; text-align: center; margin: 0px 0 25px 0;}
    .home-victorystories .victoryslider .vtsbox .detail .vname {text-align: center;}
    .home-victorystories .victoryslider .owl-carousel .owl-nav {display: none;}
    .home-victorystories .victoryslider .owl-carousel .owl-nav .owl-next {right: -88px;}
    .home-victorystories .victoryslider .owl-carousel .owl-nav .owl-prev {left: -88px;}
    .home-victorystories .victoryslider .owl-carousel .owl-nav .owl-prev, .home-victorystories .victoryslider .owl-carousel .owl-nav .owl-next {top: 40%;}
    .home-areyoucoach {min-height: 163px; background-size: 100%; padding: 25px 0 0px 0;}
    .home-areyoucoach h1 {font-size: 20px; line-height: 26px !important; margin: 0 0 20px 0;}
    .home-areyoucoach br {display: none;}
    .home-areyoucoach .btndiv .btn-primary {padding: 0 20px; line-height: 40px;}
    .footer .col-sm-2 {display: none;}
    .footer .image img {width: 150px;}
    .footer .footer-address ul li img {float: left; margin: 7px 10px 15px 0;}
    .footer .footerinfo .content {padding: 0 0px 25px 0;}
    .coach-listing .refinesearch {margin: 0px 0 30px 0;}
    .coach-listing .coach-box .detail {margin: 10px 0 0 0px; text-align: center;}
    .coach-listing .coach-box .detail .catename {margin: 0 !important;}
    .coach-listing .coach-box h3 {margin: 10px 0 12px 0;}
    .coach-listing .coach-box .address {font-size: 13px;}
    .coach-listing .coach-box .address img {float: left; margin: 4px 10px 20px 0;}
    .coach-individual .ci-coverimage img {height: 100px;}
    .coach-individual .ci-right .ci-rating-box h2 .rating {position: relative; top: 0; left: 0px;}
    .aboutus-page .dontwish {min-height: 322px;}
    .aboutus-page .dontwish h1 {font-size: 26px; margin: 20px 0 20px 0; line-height: 35px !important;}
    .aboutus-page .dontwish .col-sm-7 {flex: 0 0 100%; max-width: 100%;}
    .aboutus-page .dontwish .content {font-size: 14px; padding: 0 0px 0 0; line-height: 26px;}
    .aboutus-page .aboutreakt {padding: 50px 0;}
    .aboutus-page .aboutreakt h1 {font-size: 35px;}
    .aboutus-page .aboutreakt .content {font-size: 15px; line-height: 26px; margin: 0 0 30px 0;}
    .aboutus-page .bestronger h1 {font-size: 35px; margin: 0px 0 30px 0;}
    .aboutus-page .bestronger h2 {font-size: 22px; line-height: 35px !important;}
    .aboutus-page .bestronger .content {font-size: 15px; line-height: 26px;}
    .aboutus-page .bestronger .image img {max-width: 100%; margin: 15px 0 0 0;}
    .aboutus-page .whychooseus h1 {font-size: 21px; line-height: 35px !important; margin: 0px 0 80px 0;}
    .aboutus-page .whychooseus {padding: 50px 0px 50px 0;}
    .aboutus-page .whychooseus .wcubox.one {margin-bottom: 90px;}
    .aboutus-page .whychooseus .wcubox.two {margin-bottom: 90px;}
    .aboutus-page .whychooseus .wcubox.three {margin-bottom: 90px;}
    .whatweoffer .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
    .aboutus-page .whatweoffer {padding: 90px 0px 0px 0;}
    .aboutus-page .whatweoffer .content {margin: 0 0 30px 0; padding: 0 0px 0 0;}
    .aboutus-page .whatweoffer .servicebox {max-width: 100%; padding: 35px;}
    .contactus-page .contact-detail {margin: -40px 0 0px 0; padding: 20px 20px; position: relative;}
    .contactus-page .contact-detail h1 {font-size: 18px; line-height: 30px !important; margin: 0px 0 20px 0;}
    .contactus-page .contact-detail .condiv .call {margin: 0 0px 10px 0; display: block;}
    .contactus-page .contact-detail .condiv .email {margin: 0 0px 15px 0; display: block;}
    .contactus-page .contact-detail .content {font-size: 15px; margin: 0px 0 20px 0;}
    .contactus-page .contact-form {padding: 40px 0 50px 0;}
    .contactus-page .contact-form .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
    .faq-page .faq-search .col-sm-2, .faq-page .faq-search .col-sm-8 {flex: 0 0 100%; max-width: 100%;}
    .faq-page .faq-search {min-height: 150px; background-size: cover; padding: 20px 0px 0px 0;}
    .faq-page .faq-search h1 {font-size: 15px; line-height: 20px !important;}
    .faq-page .faq-search .form-control {height: 45px;}
    .faq-page .faq-search .btn-outline-secondary {line-height: 43px;}
    .faq-page .faq-bottom {padding: 50px 0 0 0;}
    .faq-page .faq-bottom .col-sm-4, .faq-page .faq-bottom .col-sm-8 {flex: 0 0 100%; max-width: 100%;}
    .faq-page .faq-bottom .faq-left {margin: 0 0 30px 0;}
    .faq-page .faq-bottom .faq-right .accordion .card .card-header {padding: 15px 15px;}
    .faq-page .faq-bottom .faq-right .accordion .card .card-body {padding: 15px 15px;}
    .faq-page .faq-bottom .faq-left .fl-body ul li a {padding: 15px;}
    .faq-page .faq-search .btn-outline-secondary {padding: 0 15px;}
    .login-page .container-fluid {max-width: 100%; padding: 0 15px;}
    .login-page .row.main {margin: 0 -15px;} 
    .login-page .col-sm-6.main {padding: 0 15px; flex: 0 0 100%; max-width: 100%;}
    .login-page .image img {display: none;}
    .login-page .logoimg {position: relative; margin: 15px auto 0 auto; height: auto; width: 230px;}
    .login-page .login-box h1 {margin: 50px 0 50px 0;}
    .login-page .login-box .btn-primary {margin-bottom: 30px;}
    .login-page .loginsociel a, .login-page .loginsociel button {width: 140px; margin: 0 5px 0 0;}
    .login-page.forgetpassword .login-box h1 {margin: 50px 0 50px 0;}
    .userdatail .profileimage {display: block !important; text-align: center !important; height: auto !important; margin-bottom: 30px !important;}
    .userdatail .circle {width: 94px !important; margin: 0 auto 15px auto !important;}
    .Left-Side .userdatail .profileimage label.btn {margin-left: 0px !important;}
    .dashboard-card .dash-card-title h1 {color: $secondary;}
    .dashboard-card {margin-top: 0px;}
    .dashboard-card .dash-card-body {padding: 15px;}
    .coach-dashboard .dashboard-top {margin: 0 0px 20px 0px;}
    .coach-dashboard .dashboard-top .media {margin-bottom: 30px;}
    .transactions-page .table td {white-space: nowrap;}
    .coach-dashboard .dashboard-calender .calender-sec .btn-toolbar .btn-info {padding: 0 10px;line-height: 25px;font-size: 13px;height: 27px;}
    .coach-dashboard .dashboard-calender .calender-sec .btn-toolbar .btn-primary {height: 27px; padding: 0 5px;}
    .coach-dashboard .dashboard-calender .calender-sec .btn-toolbar .btn-info {margin-left: 0px;}
    .coach-dashboard .dashboard-calender .calender-sec .fc-header-toolbar h2.fc-toolbar-title {font-size: 18px; margin: 0 0 0 120px;}
    .coach-dashboard .dashboard-calender .calender-sec .fc-header-toolbar .fc-button-group .fc-button-primary {padding: 0 10px;line-height: 25px;font-size: 13px;}
    .coach-dashboard .dashboard-calender .calender-sec .fc .fc-button-group {display: none;}
    .dashboard-card .dash-card-title ul {margin: 0px 0px 15px 0px; display: block;}
    .dashboard-card .dash-card-title ul li {padding: 0px 0px 15px 0px;}
    .dashboard-card .dash-card-title ul a {color: $secondary; font-size: 22px;}
    .dashboard-card .dash-card-title ul a:hover, .dashboard-card .dash-card-title ul a.active {color: $primary;}
    .booking-box {padding: 20px 20px 45px 20px;}
    .booking-box .profile-group {width: 100%; display: block;}
    .booking-box .profile-group .photo {margin: 0 auto;}
    .booking-box .time-group {width: 100%; padding-top: 15px; text-align: center;}
    .booking-box .cost {width: 100%; padding-top: 0px; display: block; text-align: center;}
    .booking-box .user-details {padding-top: 10px; text-align: center;}
    .booking-box .user-details .location {font-size: 15px; padding: 0 0px 0 0;}
    .booking-box .user-details .location img {margin: 2px 0px 0px 0;}
    .booking-box .time-group > .row [class*="col"] {display: block;}
    .dashboard-card .col-sm-9.cdetail, .dashboard-card .col-sm-3.cdetail {flex: 0 0 100%; max-width: 100%;}
    .booking-details .sessionbtn .btn-primary {padding: 0 21px; margin: 0 10px 10px 0;}
    .booking-details .detail .date {margin: 0 15px 0px 0;}
    .booking-details .coach.media .address img {margin: 2px 0px 0px 0;}
    .booking-details .customer.media .address img {margin: 2px 0px 0px 0;}
    .dashboard-card .col-sm-9.cdetail .dash-card-body {min-height: auto;}
    .messagesec {margin: 30px 0 0 0;}
    .messagesec .messages .mes-footer .form-control {width: 86%;}
    .messagesec .messages .header h5 {width: 90px;}
    .booking-details .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
    .booking-details .titletea {margin-top: 20px;}
    .booking-details .detail {text-align: center;}
    .booking-details .detail .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
    .booking-details .coach.media {display: block;}
    .booking-details .coach.media .image {margin-right: 0px; margin-bottom: 15px; text-align: center;}
    .booking-details .coach.media h5 {text-align: center;}
    .booking-details .coach.media .rating {text-align: center;}
    .booking-details .coach.media .address {text-align: center;}
    .booking-details .sessionbtn {text-align: center;}
    .booking-details .sessionbtn .btn-primary {margin: 0 5px 10px 5px;}
    .booking-details .customer.media {display: block;}
    .booking-details .customer.media .image {text-align: center; margin-right: 0;}
    .booking-details .customer.media h5 {text-align: center; margin: 15px 0 7px 0;}
    .booking-details .customer.media .address {text-align: center;}
    .booking-details .order-status {text-align: center;}
    .custom-modal .modal-dialog {min-width: auto; padding: 48px 10px 48px;}
    .custom-modal .modal-dialog .modal-header .close {margin: -12px 0px 0px 0px; padding: 0;}
    .dashboard-card .pagesubmenu {margin-bottom: 30px;}
    .dashboard-card .pagesubmenu ul {margin: 0px 0px 0px 0px; display: block;}
    .dashboard-card .pagesubmenu ul li {padding: 0px 0px 20px 0; display: block;}
    .dashboard-card .pagesubmenu ul a {padding-bottom: 0px; font-size: 15px;}
    .transactions-page .totalcon {font-size: 13px;}
    .transactions-page .totalcon span {font-size: 15px; margin: 0 0 0 5px;}
    .notification-page .table td {white-space: nowrap;}
    .addresses .btndiv {margin: 0 0 20px 0;}
    .addresses .addess-box .card-header {padding: 15px 15px !important; display: block !important;}
    .addresses .addess-box .card-header .title {margin-bottom: 10px;}
    .addresses .addess-box .card-body {padding: 15px 15px !important;}
    .addresses .setting-form .col-10 {flex: 0 0 100%; max-width: 100%;}
    .addresses .setting-form .btn-primary {padding: 0 20px;}
    .addresses .setting-form .btn-link {margin: 0px 0 0 8px;}
    .update-profile .profileimage {display: block !important; text-align: center !important; height: auto !important; margin-bottom: 30px !important;}
    .update-profile .circle {width: 94px !important; margin: 0 auto 15px auto !important;}
    .update-profile .profileimage .btn {margin-left: 0px !important;}
    .update-profile .mat-radio-label {margin-right: 44px;}
    .updatebackground .pastexperience .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
    .updatebackground .pastexperience .col-sm-4 {flex: 0 0 100%; max-width: 100%;}
    .updatebackground .pastexperience .btn-outline-primary {line-height: 35px; font-size: 14px; padding: 0 20px;}
    .updatebackground .pastexperience .petop .btndiv {margin: 0px 0 0 0;}
    .updatebackground .pastexperience .petop .content {padding: 0 0px 15px 0;}
    .updatebackground .pastexperience .petop .btndiv .deletecard2 {width: 35px; height: 35px;}
    .payment-page .btn-primary {padding: 0 45px;}
    .availibility-page .mb-2 {padding-right: 85px;}
    .dashboard-card .dash-card-body {min-height: auto;}
    .updatebackground .pastexperience .btn-primary {padding: 0 40px;}
    .writetestimonial .testimonialform .mat-radio-label-content {padding-left: 15px;}
    .writetestimonial .testimonialform .rating ngb-rating span {margin-right: 2px;}
    .writetestimonial .example-radio-group {margin-left: -5px !important;}
    .addresses .addess-box .card-body .titletxt {display: block;}
    .addresses .addess-box .card-body p.card-text {display: block !important;}
    .update-profile .crprbtn {padding: 0px 0 50px 0; text-align: center;}
    .update-profile .profileimage {margin-bottom: 20px !important;}
    .update-profile .crprbtn .btn-primary {width: 250px; margin: 0 0px 10px 0;}
    .messagesec .messages .mes-footer .form-control {width: 78%;}
    .public-group .creatbtn {margin: 0px 0 30px 0;}
    .booking-box.public h3 {text-align: center;}
    .booking-box.public .content {text-align: center;}
    .public-booking-view .btndiv {margin: 15px auto 0 auto; text-align: center; width: 250px;}
    .public-booking-view .btndiv .btn-primary {margin-top: 10px;}
}

@media (min-width: 320px) and (max-width: 374px) {
    .availibility-page .mb-2 {padding: 0 110px 0 0;}
    .messagesec .messages .mes-footer .form-control {width: 74%;}
}